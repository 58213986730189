<template>
  <VoucherCheckoutWrapper
    :voucher-purchase="voucherPurchase"
    :is-voucher-purchase-expired="isVoucherPurchaseExpired"
  >
    <form
      v-if="isReady"
      class="buyer-form"
      @submit.prevent="submitBuyerData"
    >
      <div v-if="buyer">
        <div class="form-wrapper">
          <div class="form-box">
            <div
              class="gift-form-toggle"
              :class="{ floating: giftDto.isGift }"
            >
              <h3
                v-if="!giftDto.isGift"
                class="header-title"
              >
                <DynLang by-key="checkout-getaway-gift-title" />
              </h3>
              <SplToggleSwitch
                v-model="giftDto.isGift"
                @changed="onGiftCheckClicked"
              />
            </div>

            <div
              v-if="giftDto.isGift"
              class="gift-form"
            >
              <div class="gift-form__form">
                <div class="gift-form__form__header">
                  <h3 class="gift-form__form__header__title">
                    <DynLang by-key="checkout-getaway-gift-form-header" />
                  </h3>
                </div>

                <div class="gift-form__form__fields form-table">
                  <div class="form-table-top form-table-row form-table-double">
                    <SplInputBox
                      id="gift-name"
                      v-model="giftDto.beneficiary.name"
                      label-translation-key="name-and-surname"
                      variant="base"
                      type="text"
                      :has-border="false"
                      :is-valid="isGiftNameValid"
                      :max-length="32"
                      @blur="onGiftInputExit('name', giftDto)"
                    />
                  </div>
                </div>

                <div
                  v-if="!isGiftNameValid"
                  class="form-errors-my"
                >
                  <span>*<DynLang
                    by-key="buyer-form-name-and-surname-error"
                    capitalize-first
                  /></span>
                </div>

                <div class="voucher-preview">
                  <div class="voucher-preview__header">
                    <DynLang
                      by-key="gift-voucher"
                      uppercase
                    />
                  </div>

                  <div class="voucher-preview__client">
                    <p class="voucher-preview__client-name">
                      <span class="voucher-preview__client-name__for">Para:</span>
                      <span class="voucher-preview__client-name__name">{{ clientName }} {{ clientSurname }}</span>
                    </p>
                    <div class="voucher-preview__client-services">
                      <p>
                        <DynLang
                          by-key="service"
                          plural
                          capitalize-all
                        />:
                      </p>
                      <ul>
                        <li
                          v-for="service in purchaseServiceCollection"
                          :key="service.uuid"
                        >
                          <span class="voucher-preview__client-services-name">
                            <DynLang
                              :by-raw="service.texts"
                              key-for-raw="name"
                              capitalize-all
                            />
                          </span>
                          <span class="voucher-preview__client-services-units">
                            (<span class="units-icon"><person-icon :width="12" /></span>
                            <span>x {{ getPeopleCount(service) }}</span>)
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="voucher-preview__footer">
                    www.spalopia.com
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="scroll-target-form-top" />

          <div class="form-box">
            <div class="as-gift-header">
              <h3>
                <DynLang
                  by-key="checkout-voucher-form-title"
                  capitalize-first
                />
              </h3>
            </div>

            <div class="form-table">
              <div class="form-table-top-left form-table-row form-table-double-mobile">
                <SplInputBox
                  id="buyer-name"
                  v-model="buyer.name"
                  label-translation-key="name"
                  variant="base"
                  type="text"
                  :has-border="false"
                  :is-valid="isNameValid"
                  :max-length="20"
                  @blur="onNameBlur"
                />
              </div>
              <div class="form-table-row form-table-double-mobile">
                <SplInputBox
                  id="buyer-surname"
                  v-model="buyer.surname"
                  label-translation-key="surname"
                  type="text"
                  variant="base"
                  :has-border="false"
                  :is-valid="isSurnameValid"
                  :max-length="26"
                  @blur="onSurnameBlur"
                />
              </div>

              <div class="form-table-row form-table-double">
                <SplEmailInput
                  id="buyer-email"
                  v-model="buyer.email"
                  :is-valid="isEmailValid"
                  @blur="onEmailBlur"
                />
              </div>

              <div class="form-table-bottom-left form-table-bottom-right form-table-row form-table-double">
                <SplInputBox
                  id="buyer-phone"
                  v-model="buyer.phone"
                  type="tel"
                  variant="base"
                  :has-border="false"
                  label-translation-key="phone"
                  :max-length="20"
                  :is-valid="isPhoneValid"
                  @blur="onPhoneBlur"
                  @on-update="onPhoneBlur"
                />
              </div>

              <div
                v-if="formErrors.length"
                class="form-errors"
              >
                <div
                  v-for="(error, i) in formErrors"
                  :key="`vp-error-${i}`"
                  class="form-error-label"
                >
                  <span v-if="error.key === 'name'">
                    *<DynLang
                      by-key="buyer-form-name-error"
                      capitalize-first
                    />
                  </span>
                  <span v-else-if="error.key === 'surname'">
                    *<DynLang
                      by-key="buyer-form-surname-error"
                      capitalize-first
                    />
                  </span>
                  <span v-else-if="error.key === 'email'">
                    *<DynLang
                      by-key="buyer-form-email-error"
                      capitalize-first
                    />
                  </span>
                  <span v-else-if="error.key === 'phone'">
                    *<DynLang
                      by-key="buyer-form-phone-error"
                      capitalize-first
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div id="scroll-target-form-conditions" />

          <div class="form-box">
            <VoucherPurchaseConditions
              v-if="voucherPurchase"
              :voucher-purchase="voucherPurchase"
              :external-is-expanded="isTermAndConditionsExpanded"
            />

            <div class="form-checks">
              <SplCheckbox
                id="offer-approve"
                v-model="buyer.allowNotifications"
                class="form-checks-item"
              >
                <DynLang
                  by-key="offer-accept-label"
                  capitalize-first
                />
              </SplCheckbox>

              <SplCheckbox
                id="terms-and-conditions"
                v-model="termsAndConditions"
                class="form-checks-item"
                :is-valid="termsAndConditionsValid"
                tooltip-translatable-content-key="purchase-has-to-accept-conditions"
              >
                <DynLang
                  by-key="ive-read-and-accept"
                  capitalize-first
                />
                <SplNuxtLink
                  is-external
                  is-target-blank
                  class="form-checks-item__terms"
                  to="/condiciones-generales-compra"
                >
                  <DynLang
                    by-key="terms-and-conditions"
                    capitalize-first
                  />
                </SplNuxtLink>
                <DynLang
                  by-key="and the"
                />
                <span
                  class="form-checks-item__terms"
                  @click.prevent="setIsTermAndConditionsExpanded(true)"
                >
                  <DynLang
                    by-key="service-terms-and-conditions"
                    capitalize-first
                  />
                </span>
              </SplCheckbox>
            </div>

            <div
              v-if="formErrors.length"
              class="form-errors"
            >
              <div
                v-for="(error, i) in formErrors"
                :key="`vp-error-${i}`"
                class="form-error-label"
              >
                <span v-if="error.key === 'termsAndConditions'">
                  *<DynLang
                    by-key="buyer-form-terms-error"
                    capitalize-first
                  />
                </span>
              </div>
            </div>
          </div>

          <div class="form-box small coupon-notice">
            <p>
              <DynLang
                by-key="if-thereis-coupon-applies-next-step"
                capitalize-first
              />
            </p>
          </div>
        </div>
        <div class="form-btns">
          <div>
            <SplNuxtLink
              is-external
              no-lang
              :to="backUri"
              class="form-btns-back"
            >
              <DynLang
                by-key="back"
                capitalize-first
              />
            </SplNuxtLink>
          </div>

          <div>
            <SplButton
              class="form-btns-contlarge"
              type="submit"
              variant="acent"
              size="lg"
            >
              <DynLang
                by-key="continue"
                capitalize-all
              />
            </SplButton>
            <SplButton
              class="form-btns-contsmall"
              type="submit"
              variant="acent"
              size="sm"
            >
              <DynLang
                by-key="continue"
                capitalize-all
              />
            </SplButton>
          </div>
        </div>
      </div>
    </form>
  </VoucherCheckoutWrapper>
</template>

<script lang="ts">
import { useVoucherBuyerForm } from '~/core/composable/voucher-purchase/useVoucherBuyerForm'

import VoucherCheckoutWrapper from '~/core/components/voucher-purchase/VoucherCheckoutWrapper.vue'
import VoucherPurchaseConditions from '~/core/components/voucher-purchase/VoucherPurchaseConditions.vue'
import SplInputBox from '~/core/components/shared/form/SplInputBox.vue'
import SplToggleSwitch from '~/core/components/shared/form/SplToggleSwitch.vue'
import SplEmailInput from '~/core/components/shared/form/SplEmailInput.vue'
import SplButton from '~/core/components/shared/form/SplButton.vue'
import SplCheckbox from '~/core/components/shared/form/SplCheckbox.vue'
import SplNuxtLink from '~/core/components/shared/SplNuxtLink.vue'
import PersonIcon from '~/core/components/shared/icons/Person.icon.vue'

import { useNavPopStateReload } from '~/core/composable/shared/useNavPopStateReload'

import { Service } from '~/core/ts/entity/Service'
import { Voucher } from '~/core/ts/entity/Voucher'

import ServiceNavigationStack from '~/core/ts/repository/ServiceNavigationStackRepository'

definePageMeta({
  layout: 'checkout',
})

export default defineNuxtComponent({
  components: {
    VoucherCheckoutWrapper,
    VoucherPurchaseConditions,
    SplInputBox,
    SplToggleSwitch,
    SplEmailInput,
    SplButton,
    SplCheckbox,
    SplNuxtLink,
    PersonIcon,
},
  setup() {
    const runtime = useRuntimeConfig()

    const isTermAndConditionsExpanded = ref(false)
    function setIsTermAndConditionsExpanded(value: boolean) {
      isTermAndConditionsExpanded.value = value
    }

    const {
      isReady,

      formErrors,
      buyer,
      giftDto,
      setGiftDto,
      isGiftNameValid,
      onGiftInputExit,
      countries,

      isNameValid,
      isSurnameValid,
      isEmailValid,
      isPhoneValid,

      termsAndConditions,
      termsAndConditionsValid,

      onNameBlur,
      onSurnameBlur,
      onEmailBlur,
      onPhoneBlur,

      validateForm,

      voucherPurchase,
      isVoucherPurchaseExpired,
    } = useVoucherBuyerForm()

    function getCountryValue(isoCode: string) {
      return countries.value.find(({ iso }) => iso === isoCode)
    }

    const submitBuyerData = () => {
      setGiftDto(giftDto.value)
      validateForm()

      setTimeout(() => {
        if (!formErrors.value.length) {
          return
        }

        const top = document.getElementById('scroll-target-form-top')
        const conditions = document.getElementById('scroll-target-form-conditions')

        if (top && conditions) {
          let offset = conditions.offsetTop - 64
          formErrors.value.forEach((error) => {
            if (error.key !== 'termsAndConditions') {
              offset = top.offsetTop - 32
            }
          })

          window.scroll({
            top: offset,
            left: 0,
            behavior: 'smooth',
          })
        }
      }, 100)
    }

    const onGiftCheckClicked = (value: boolean) => {
      const dto = {
        isGift: value,
        beneficiary: { ...giftDto.value.beneficiary },
      }

      onGiftInputExit('check', dto)
    }

    const clientName = computed(() => {
      if (giftDto.value.isGift) {
        return giftDto.value.beneficiary.name
      }

      return buyer.value.name
    })

    const clientSurname = computed(() => {
      if (giftDto.value.isGift) {
        return ''
      }

      return buyer.value.surname
    })

    const getPeopleCount = (service: Voucher['items'][0]) => {
      if (service) {
        const data = service

        const units = data.units
        const min = data.capacity.minimum
        const inc = data.capacity.incrementing

        return min + (units - 1) * inc
      }

      return 0
    }

    let serviceToPurchaseStore: Service | null = null

    const purchaseServiceCollection = computed(() => {
      if (!voucherPurchase.value) {
        return []
      }
      return voucherPurchase.value.vouchers.flatMap(({ items }) => items)
    })

    const serviceToPurchase = computed(() => {
      if (!purchaseServiceCollection.value) {
        return null
      }

      if (serviceToPurchaseStore) {
        return serviceToPurchaseStore
      }

      purchaseServiceCollection.value.forEach((serv) => {
        const tmp = ServiceNavigationStack.getByUuid(serv.uuid)
        if (tmp) {
          serviceToPurchaseStore = tmp
        }
      })

      return serviceToPurchaseStore
    })

    const backUri = computed(() => {
      if (serviceToPurchase.value) {
        return serviceToPurchase.value.uri
      }

      return runtime.public.urls.spasBaseUrl
    })

    useNavPopStateReload()

    return {
      isReady,

      formErrors,
      backUri,
      buyer,
      giftDto,
      isGiftNameValid,
      onGiftInputExit,
      onGiftCheckClicked,
      clientName,
      clientSurname,
      countries,

      isNameValid,
      isSurnameValid,
      isEmailValid,
      isPhoneValid,

      termsAndConditions,
      termsAndConditionsValid,

      onNameBlur,
      onSurnameBlur,
      onEmailBlur,
      onPhoneBlur,

      getCountryValue,
      submitBuyerData,
      purchaseServiceCollection,
      serviceToPurchase,
      getPeopleCount,
      isTermAndConditionsExpanded,
      setIsTermAndConditionsExpanded,

      voucherPurchase,
      isVoucherPurchaseExpired,
    }
  },
})
</script>

<style lang="scss" scoped>
@import url('~/core/components/shared/form/form-table.scss');
.spa-term-conditions {
  p {
    @apply my-1;
  }

  h4 {
    @apply my-2;
    @apply font-bold;
    @apply text-spl-primary-dark;
  }
}
.form-errors-my {
  @apply text-red-900;
  @apply my-2;
}

.header-title {
  @apply font-semibold;
  @apply text-spl-dark;
  @apply text-xl;
}

.buyer-form {
  @apply text-lg;
  @apply mb-6;
}
.gift-form-toggle {
  @apply flex;
  @apply justify-between;
  @apply items-center;

  span {
    @apply text-spl-dark;
    @apply font-semibold;
  }
}
.gift-form-toggle.floating {
  float: right;
}
.gift-form {
  @apply bg-white;

  &__form {
    &__header {
      @apply flex;
      @apply justify-between;
      @apply mt-1;
      @apply mb-4;

      &__title {
        @apply text-spl-dark;
        @apply font-semibold;
      }
    }

    &__fields {
      @apply my-2;
    }
  }
}
.voucher-preview {
  @apply relative;
  @apply text-spl-dark;
  @apply text-base;

  &-name {
    @apply absolute;
    top: 14%;
    left: 11.5%;
  }

  &-services {
    @apply absolute;
    top: 30%;
    left: 14%;

    &-name {
      @apply font-bold;
      @apply mr-2;
    }

    &-units {
      @apply text-spl-primary;
    }
  }
}

.voucher-preview {
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #f4f0ec;
  background-image: url('https://static.spalopia.app/44a6509d-65eb-54d1-a3cf-edd04613ac53/s3fs-public/sp-bono_1.png');

  &__header {
    @apply py-1;
    @apply px-2;
    @apply bg-spl-primary;
    @apply text-white;
  }

  &__client {
    @apply p-4;

    padding-bottom: 20%;

    &-name {
      &__for {
        @apply text-spl-primary-dark;
      }
      &__name {
        @apply ml-2;
        @apply text-spl-dark;
      }
    }

    &-services {
      @apply text-spl-primary-dark;
      li {
        @apply flex;
        @apply items-center;
      }

      &-name {
        @apply text-spl-dark;
        @apply ml-50p;
        @apply mr-2;
      }
      &-units {
        @apply text-spl-primary-dark;

        @apply flex;
        @apply items-center;

        .units-icon {
          @apply mr-1;
        }
      }
    }
  }

  &__footer {
    @apply text-sm;
    @apply text-center;
    @apply bg-spl-primary;
    @apply text-white;
  }
}
.as-gift-header {
  @apply mb-8;
  @apply text-spl-dark;
  @apply font-semibold;
  @apply text-xl;
}

.coupon-notice {
  @apply text-spl-primary-dark;
}
</style>
