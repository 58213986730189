<template>
  <VoucherCheckoutWrapper
    :voucher-purchase="voucherPurchase"
    :is-voucher-purchase-expired="isVoucherPurchaseExpired"
    :step-index="1"
  >
    <div class="voucher-purchase-wrapper">
      <div
        v-if="isReady && voucherPurchase"
        class="voucher-purchase-payment"
      >
        <div
          v-if="!appliedCoupon"
          class="coupon-form-toggle"
        >
          <h3 class="coupon-form-toggle__header-title">
            <DynLang
              by-key="i-have-discount-coupon"
              capitalize-first
            />
          </h3>
          <SplToggleSwitch
            :value="showCouponInput"
            @input="setShowCouponInput(!showCouponInput)"
          />
        </div>

        <div
          v-else
          class="coupon-form-with-code"
        >
          <div class="coupon-form-with-code__label">
            <DynLang
              by-key="coupon-XX-applied"
              :params="{label: appliedCoupon.alias}"
              capitalize-first
            />
          </div>
        </div>

        <div v-if="showCouponInput">
          <div class="coupon-form-body">
            <div class="coupon-form-body__input">
              <SplInputBox
                id="coupon-code"
                v-model="couponCode"
                label-translation-key="code"
                variant="base"
                type="text"
                :has-border="true"
                :is-valid="!couponCodeError"
                :max-length="32"
                :disabled="!!appliedCoupon"
                @input="resetCouponValidation"
              />
            </div>

            <div v-if="!!appliedCoupon">
              <SplButton
                class="coupon-form-body__btn"
                variant="acent-green"
                size="sm"
                @click="onCouponRemoveClick"
              >
                <DynLang
                  by-key="remove"
                  capitalize-first
                />
              </SplButton>
            </div>
            <div v-else>
              <SplButton
                class="coupon-form-body__btn"
                variant="acent-green"
                size="sm"
                @click="onCouponSendClick"
              >
                <DynLang
                  by-key="apply"
                  capitalize-first
                />
              </SplButton>
            </div>
          </div>

          <div
            v-if="couponCodeSuccess"
            class="coupon-form-success"
          >
            <DynLang
              by-key="coupon-XX-applied-with-XX-value"
              :params="{label: couponCode, discount: currentCouponDiscount}"
            />
          </div>

          <div
            v-if="couponCodeError"
            class="coupon-form-error"
          >
            <div v-if="couponCode.length">
              {{ couponCodeErrorMessage }}
            </div>
          </div>
        </div>
      </div>

      <div class="voucher-purchase-payment">
        <div class="options">
          <h3 class="header">
            <DynLang
              by-key="payment-method"
              capitalize-first
            />
          </h3>
          <h4 class="subheader">
            <DynLang
              by-key="all-transtactions-are-safe"
              capitalize-first
            />
          </h4>

          <Badge
            color="secondary"
            class="badge"
          >
            <DynLang
              v-if="isChristmas"
              by-key="summary-change-refund-chrst"
              capitalize-first
            />
            <DynLang
              v-else
              by-key="summary-change-refund"
              capitalize-first
            />
          </Badge>

          <!-- Métodos de pago -->
          <div>
            <ul
              v-if="isReady && voucherPurchase"
              class="payment-method-list"
            >
              <li
                v-for="paymentMethod in paymentMethodCollection"
                :key="paymentMethod.type"
                class="payment-method-list__item"
              >
                <SplRadioButton
                  :id="`payment-method-radiobtn-${paymentMethod.nameLangKey}`"
                  class="payment-method-list__item__radio"
                  :label="paymentMethod.name"
                  :label-translation-key="paymentMethod.nameLangKey"
                  name="desktopPaymentOptions"
                  :value="getPaymentMethodIsSelected(paymentMethod)"
                  @change="setPaymentMethodSelected(paymentMethod)"
                >
                  <template #extra>
                    <PaymentMethodImages :payment-method-type="paymentMethod.type" />
                  </template>
                </SplRadioButton>
                <div>
                  <client-only v-if="getPaymentMethodIsSelected(paymentMethod)">
                    <RedsysPayment
                      v-if="isRedsysPayment(paymentMethod) && fuc"
                      :voucher-order-i-d="voucherOrderID"
                      :purchase-uuid="voucherPurchase.uuid"
                      :fuc="fuc"
                      @on-token-received="onRedsysToken"
                      @on-token-reset-requested="onRedsysResetRequested"
                    />

                    <PaypalPayment
                      v-else-if="isPaypalPayment(paymentMethod)"
                      sub-type="paypal"
                      :voucher-purchase="voucherPurchase"
                    />

                    <PaypalPayment
                      v-else-if="isPaypalMultibancoPayment(paymentMethod)"
                      sub-type="paypal-multibanco"
                      :voucher-purchase="voucherPurchase"
                    />

                    <PaypalApplePayment
                      v-else-if="isPaypalApplePayPayment(paymentMethod)"
                      :voucher-purchase="voucherPurchase"
                    />
                  </client-only>
                </div>

                <hr class="payment-method-list__item__separator">
              </li>
            </ul>
          </div>
        </div>

        <div>
          <BenefitsCard
            :tags="['SSL', 'SAFE_PAYMENT_ENSURED']"
            :can-close="false"
            :is-title-displayed="false"
          />
        </div>
      </div>

      <div>
        <div class="voucher-purchase-payment-buttons">
          <SplNuxtLink
            :to="backUrl"
            is-external
            class="form-btns-back"
          >
            <DynLang
              by-key="back"
              capitalize-all
            />
          </SplNuxtLink>
        </div>
      </div>
    </div>
  </VoucherCheckoutWrapper>
</template>

<script lang="ts">
import VoucherCheckoutWrapper from '~/core/components/voucher-purchase/VoucherCheckoutWrapper.vue'
import RedsysPayment from '~/core/components/voucher-purchase/RedsysPayment.vue'
import PaypalPayment from '~/core/components/voucher-purchase/PaypalPayment.vue'
import PaypalApplePayment from '~/core/components/voucher-purchase/PaypalApplePayment.vue'
import PaymentMethodImages from '~/core/components/voucher-purchase/PaymentMethodImages.vue'
import SplToggleSwitch from '~/core/components/shared/form/SplToggleSwitch.vue'

import BenefitsCard from '~/core/components/spa/BenefitsCard.vue'
import Badge from '~/core/components/shared/Badge.vue'
import SplNuxtLink from '~/core/components/shared/SplNuxtLink.vue'
import SplInputBox from '~/core/components/shared/form/SplInputBox.vue'
import SplButton from '~/core/components/shared/form/SplButton.vue'
import SplRadioButton from '~/core/components/shared/form/SplRadioButton.vue'

import { useVoucherPaymentForm } from '~/core/composable/voucher-purchase/useVoucherPaymentForm'
import { useMobileDevice } from '~/core/composable/shared/useMobileDevice'

import config from '~/core/ts/server-config'

import { PaymentMethod } from '~/core/ts/entity/payment-method/PaymentMethod'
import { PaymentMethodType } from '~/core/ts/entity/payment-method/PaymentMethodType'

definePageMeta({
  layout: 'checkout',
})

export default defineComponent({
  components: {
    VoucherCheckoutWrapper,
    RedsysPayment,
    PaypalPayment,
    PaypalApplePayment,
    SplToggleSwitch,
    PaymentMethodImages,
    BenefitsCard,
    Badge,
    SplNuxtLink,
    SplInputBox,
    SplButton,
    SplRadioButton,
  },
  emits: [
    'reload'
  ],
  setup(_props, { emit }) {
    const {
      voucherUUID,

      isReady,

      isRedsysEnabled,
      isPaypalEnabled,
      isPaypalMultibancoEnabled,

      fuc,
      voucherOrderID,

      voucherPurchase,
      isVoucherPurchaseExpired,

      generateVoucherOrderID,
      paymentMethodCollection,
      paymentMethodSelected,
      setPaymentMethodSelected,
      // INSITE
      prepareAndAuthorizeRedsysInSitePayment,

      // Cupones
      couponCode,
      couponCodeSuccess,
      couponCodeError,
      couponCodeErrorMessage,
      resetCouponValidation,
      applyCouponToVoucherPurchase,
      removeCouponFromVoucherPurchase,
      showCouponInput,
      setShowCouponInput,
      appliedCoupon,
      currentCouponDiscount,
    } = useVoucherPaymentForm()

    const redsysInSitePaymentMethod = PaymentMethod.getInSite()
    const paypalPaymentMethod = PaymentMethod.getPaypal()
    const paypalMultibancoPaymentMethod = PaymentMethod.getPaypalMultibanco()

    const { isMobile } = useMobileDevice()

    const tokenElementSingle = ref<HTMLInputElement | null>(null)
    const errorElementSingle = ref<HTMLInputElement | null>(null)

    const backUrl = computed(() => {
      if (!voucherUUID) {
        return ''
      }

      return `/voucher/${voucherUUID}/checkout/buyer`
    })

    function getPaymentMethodIsSelected(paymentMethod: PaymentMethod) {
      const currentMethod = paymentMethodSelected.value
      if (!currentMethod) {
        return false
      }

      return paymentMethod.type === currentMethod.type
    }

    const isRedsysPaymentShownStore = ref(true)
    async function onRedsysResetRequested() {
      // Reset Redsys form status
      isRedsysPaymentShownStore.value = false
      generateVoucherOrderID()
      setTimeout(() => {
        isRedsysPaymentShownStore.value = true
      }, 1500)
    }

    function isRedsysPayment(method: PaymentMethod) {
      return method.type === PaymentMethodType.REDSYS_INSITE
    }

    function isPaypalPayment(method: PaymentMethod) {
      return method.type === PaymentMethodType.PAYPAL
    }

    function isPaypalMultibancoPayment(method: PaymentMethod) {
      return method.type === PaymentMethodType.PAYPAL_MULTIBANCO
    }

    function isPaypalApplePayPayment(method: PaymentMethod) {
      return method.type === PaymentMethodType.PAYPAL_APPLEPAY
    }

    const onRedsysToken = async (token: string) => {
      if (!token) {
        return
      }

      await prepareAndAuthorizeRedsysInSitePayment(voucherOrderID.value, token)
    }

    function scrollToPrice() {
      if (isMobile.value) {
        const price = document.getElementById('scroll-target-form-price-mobile')
        if (price) {
          const offset = price.offsetTop
          window.scroll({
            top: offset,
            left: 0,
            behavior: 'smooth',
          })
        }
      }
    }

    async function onCouponSendClick() {
      const success = await applyCouponToVoucherPurchase()
      if (success) {
        scrollToPrice()
        emit('reload')
      }
    }

    async function onCouponRemoveClick() {
      const success = await removeCouponFromVoucherPurchase()
      if (success) {
        scrollToPrice()
        emit('reload')
      }
    }

    const isChristmas = config.promos.isChristmas

    return {
      isReady,

      isRedsysEnabled,
      isPaypalEnabled,
      isPaypalMultibancoEnabled,

      fuc,

      voucherPurchase,
      isVoucherPurchaseExpired,

      paymentMethodCollection,
      paymentMethodSelected,
      setPaymentMethodSelected,
      getPaymentMethodIsSelected,

      redsysInSitePaymentMethod,
      paypalPaymentMethod,
      paypalMultibancoPaymentMethod,

      backUrl,

      voucherOrderID,
      onRedsysToken,
      onRedsysResetRequested,

      isRedsysPayment,
      tokenElementSingle,
      errorElementSingle,

      isPaypalPayment,
      isPaypalMultibancoPayment,
      isPaypalApplePayPayment,

      // cupones
      couponCode,
      couponCodeSuccess,
      couponCodeError,
      couponCodeErrorMessage,
      currentCouponDiscount,
      resetCouponValidation,
      onCouponSendClick,
      onCouponRemoveClick,
      showCouponInput,
      setShowCouponInput,
      appliedCoupon,

      isChristmas,
    }
  },
})
</script>

<style lang="scss" scoped>
.voucher-purchase-wrapper {
  @apply mb-6;
}

.voucher-purchase-payment {
  @apply bg-white;

  @apply mb-2;
  @apply py-6;
  @apply px-2;

  @screen lg {
    @apply px-6;
  }

  .header {
    @apply text-xl;
    @apply font-bold;
    @apply text-spl-dark;
    @apply my-20p;
  }

  .subheader {
    @apply italic;
    @apply text-spl-gray-4;
    @apply my-5p;
    @apply text-base;
  }

  .badge {
    @apply my-10p;
  }

  .payment-method-list {
    &__item {
      @apply pb-2;
      @apply mb-2;

      &__radio {
        @apply mb-4;
      }

      &__separator {
        @apply ml-8;
        @apply mt-4;
        @apply mb-2;
      }
    }
  }
}

.voucher-purchase-payment-buttons {
  @apply mt-2;

  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply p-3;

  @screen lg {
    @apply justify-end;
  }

  .form-btns-back {
    @apply underline;
  }
}

.coupon-form-toggle {
  @apply flex;
  @apply justify-between;
  @apply items-center;

  span {
    @apply text-spl-dark;
    @apply font-semibold;
  }

  &__header-title {
    @apply font-bold;
    @apply text-spl-dark;
  }
}
.coupon-form-toggle.floating {
  float: right;
}

.coupon-form-body {
  @apply mt-2;

  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply gap-1;

  @screen lg {
    @apply flex-row;
    @apply justify-between;
  }

  &__input {
    @apply flex-grow;
    @apply mr-2;

    @apply my-2;

    @apply w-full;
    @apply border-1;
  }

  &__btn {
    @apply w-full;
  }
}

.coupon-form-with-code {
  @apply flex justify-between items-center;

  &__label {
    @apply text-spl-primary-dark;
    @apply font-semibold;
  }
}

.coupon-form-success {
  @apply m-2;

  @apply text-green-700;
}

.coupon-form-error {
  @apply m-2;

  @apply text-spl-red;
  @apply rounded-md;
}
</style>
