
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexp2YGVpGnHNbTu_45Q3ECHMI5SSOfZtr6BCodtrAsfZwo0Meta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/index.vue?macro=true";
import { default as indexAosLa0KmgFEIhVz_iFJvXRjMPtQZzlx3vnBHgF4_45tH0Meta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startZpSUBv0igLk2IxBnVw7wEpUiH8GO4umF3TK75ggIRAEMeta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startLdJ9viFSvbKWLJEkzenU8_45V2C1ELSFkdYdjtO73N1z4Meta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutgvLfvRqhM2WmZO06h9TA4poc27BvbrQoIQ5gfCsUct8Meta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as ko9IyIj_453dh_RB_HejG97dbOVD8yd4vniBjlX5A6gN4K8Meta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmation5HdzEJOU3dDR2DZTd_45YaGayj1uvcN1e9YfA_457mp74nQMeta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationCSp5jWmG1VYAUP10xAmHNzPdsWxLMMLGAvmhooc8jSwMeta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyern3IccWTKvYL1xXqsqxU_WyNcMW9sS8vKOlXD_45u_45Dh60Meta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerLhzccsvAVqtokRuHB3uAo7jDv9pEPc6vb6_458ZJ3buFEMeta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentuxksUrfJ8yLiGfjZ8vP_45H_kVGnWYq8KwaU1C0JOhdLcMeta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengeOLhzAH6EwlwrZJffXu1argsf1armyZMxL7SlE13IM8kMeta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentDWpPV7FvIleV8e7TseBuQxq6by3cDXEonqZZj25F8iMMeta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta } from "/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexp2YGVpGnHNbTu_45Q3ECHMI5SSOfZtr6BCodtrAsfZwo0Meta?.name ?? "index",
    path: indexp2YGVpGnHNbTu_45Q3ECHMI5SSOfZtr6BCodtrAsfZwo0Meta?.path ?? "/",
    props: indexp2YGVpGnHNbTu_45Q3ECHMI5SSOfZtr6BCodtrAsfZwo0Meta?.props ?? false,
    meta: indexp2YGVpGnHNbTu_45Q3ECHMI5SSOfZtr6BCodtrAsfZwo0Meta || {},
    alias: indexp2YGVpGnHNbTu_45Q3ECHMI5SSOfZtr6BCodtrAsfZwo0Meta?.alias || [],
    redirect: indexp2YGVpGnHNbTu_45Q3ECHMI5SSOfZtr6BCodtrAsfZwo0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/index.vue")
  },
  {
    name: indexAosLa0KmgFEIhVz_iFJvXRjMPtQZzlx3vnBHgF4_45tH0Meta?.name ?? "lang",
    path: indexAosLa0KmgFEIhVz_iFJvXRjMPtQZzlx3vnBHgF4_45tH0Meta?.path ?? "/:lang?",
    props: indexAosLa0KmgFEIhVz_iFJvXRjMPtQZzlx3vnBHgF4_45tH0Meta?.props ?? false,
    meta: indexAosLa0KmgFEIhVz_iFJvXRjMPtQZzlx3vnBHgF4_45tH0Meta || {},
    alias: indexAosLa0KmgFEIhVz_iFJvXRjMPtQZzlx3vnBHgF4_45tH0Meta?.alias || [],
    redirect: indexAosLa0KmgFEIhVz_iFJvXRjMPtQZzlx3vnBHgF4_45tH0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startZpSUBv0igLk2IxBnVw7wEpUiH8GO4umF3TK75ggIRAEMeta?.name ?? "lang-voucher-start",
    path: startZpSUBv0igLk2IxBnVw7wEpUiH8GO4umF3TK75ggIRAEMeta?.path ?? "/:lang?/voucher/start",
    props: startZpSUBv0igLk2IxBnVw7wEpUiH8GO4umF3TK75ggIRAEMeta?.props ?? false,
    meta: startZpSUBv0igLk2IxBnVw7wEpUiH8GO4umF3TK75ggIRAEMeta || {},
    alias: startZpSUBv0igLk2IxBnVw7wEpUiH8GO4umF3TK75ggIRAEMeta?.alias || [],
    redirect: startZpSUBv0igLk2IxBnVw7wEpUiH8GO4umF3TK75ggIRAEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startLdJ9viFSvbKWLJEkzenU8_45V2C1ELSFkdYdjtO73N1z4Meta?.name ?? "lang-purchase-start",
    path: startLdJ9viFSvbKWLJEkzenU8_45V2C1ELSFkdYdjtO73N1z4Meta?.path ?? "/:lang?/purchase/start",
    props: startLdJ9viFSvbKWLJEkzenU8_45V2C1ELSFkdYdjtO73N1z4Meta?.props ?? false,
    meta: startLdJ9viFSvbKWLJEkzenU8_45V2C1ELSFkdYdjtO73N1z4Meta || {},
    alias: startLdJ9viFSvbKWLJEkzenU8_45V2C1ELSFkdYdjtO73N1z4Meta?.alias || [],
    redirect: startLdJ9viFSvbKWLJEkzenU8_45V2C1ELSFkdYdjtO73N1z4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutgvLfvRqhM2WmZO06h9TA4poc27BvbrQoIQ5gfCsUct8Meta?.name ?? "lang-service-booking-checkout",
    path: checkoutgvLfvRqhM2WmZO06h9TA4poc27BvbrQoIQ5gfCsUct8Meta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutgvLfvRqhM2WmZO06h9TA4poc27BvbrQoIQ5gfCsUct8Meta?.props ?? false,
    meta: checkoutgvLfvRqhM2WmZO06h9TA4poc27BvbrQoIQ5gfCsUct8Meta || {},
    alias: checkoutgvLfvRqhM2WmZO06h9TA4poc27BvbrQoIQ5gfCsUct8Meta?.alias || [],
    redirect: checkoutgvLfvRqhM2WmZO06h9TA4poc27BvbrQoIQ5gfCsUct8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: ko9IyIj_453dh_RB_HejG97dbOVD8yd4vniBjlX5A6gN4K8Meta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: ko9IyIj_453dh_RB_HejG97dbOVD8yd4vniBjlX5A6gN4K8Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: ko9IyIj_453dh_RB_HejG97dbOVD8yd4vniBjlX5A6gN4K8Meta?.props ?? false,
    meta: ko9IyIj_453dh_RB_HejG97dbOVD8yd4vniBjlX5A6gN4K8Meta || {},
    alias: ko9IyIj_453dh_RB_HejG97dbOVD8yd4vniBjlX5A6gN4K8Meta?.alias || [],
    redirect: ko9IyIj_453dh_RB_HejG97dbOVD8yd4vniBjlX5A6gN4K8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmation5HdzEJOU3dDR2DZTd_45YaGayj1uvcN1e9YfA_457mp74nQMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmation5HdzEJOU3dDR2DZTd_45YaGayj1uvcN1e9YfA_457mp74nQMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmation5HdzEJOU3dDR2DZTd_45YaGayj1uvcN1e9YfA_457mp74nQMeta?.props ?? false,
    meta: confirmation5HdzEJOU3dDR2DZTd_45YaGayj1uvcN1e9YfA_457mp74nQMeta || {},
    alias: confirmation5HdzEJOU3dDR2DZTd_45YaGayj1uvcN1e9YfA_457mp74nQMeta?.alias || [],
    redirect: confirmation5HdzEJOU3dDR2DZTd_45YaGayj1uvcN1e9YfA_457mp74nQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationCSp5jWmG1VYAUP10xAmHNzPdsWxLMMLGAvmhooc8jSwMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationCSp5jWmG1VYAUP10xAmHNzPdsWxLMMLGAvmhooc8jSwMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationCSp5jWmG1VYAUP10xAmHNzPdsWxLMMLGAvmhooc8jSwMeta?.props ?? false,
    meta: confirmationCSp5jWmG1VYAUP10xAmHNzPdsWxLMMLGAvmhooc8jSwMeta || {},
    alias: confirmationCSp5jWmG1VYAUP10xAmHNzPdsWxLMMLGAvmhooc8jSwMeta?.alias || [],
    redirect: confirmationCSp5jWmG1VYAUP10xAmHNzPdsWxLMMLGAvmhooc8jSwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyern3IccWTKvYL1xXqsqxU_WyNcMW9sS8vKOlXD_45u_45Dh60Meta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyern3IccWTKvYL1xXqsqxU_WyNcMW9sS8vKOlXD_45u_45Dh60Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyern3IccWTKvYL1xXqsqxU_WyNcMW9sS8vKOlXD_45u_45Dh60Meta?.props ?? false,
    meta: buyern3IccWTKvYL1xXqsqxU_WyNcMW9sS8vKOlXD_45u_45Dh60Meta || {},
    alias: buyern3IccWTKvYL1xXqsqxU_WyNcMW9sS8vKOlXD_45u_45Dh60Meta?.alias || [],
    redirect: buyern3IccWTKvYL1xXqsqxU_WyNcMW9sS8vKOlXD_45u_45Dh60Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerLhzccsvAVqtokRuHB3uAo7jDv9pEPc6vb6_458ZJ3buFEMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerLhzccsvAVqtokRuHB3uAo7jDv9pEPc6vb6_458ZJ3buFEMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerLhzccsvAVqtokRuHB3uAo7jDv9pEPc6vb6_458ZJ3buFEMeta?.props ?? false,
    meta: buyerLhzccsvAVqtokRuHB3uAo7jDv9pEPc6vb6_458ZJ3buFEMeta || {},
    alias: buyerLhzccsvAVqtokRuHB3uAo7jDv9pEPc6vb6_458ZJ3buFEMeta?.alias || [],
    redirect: buyerLhzccsvAVqtokRuHB3uAo7jDv9pEPc6vb6_458ZJ3buFEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentuxksUrfJ8yLiGfjZ8vP_45H_kVGnWYq8KwaU1C0JOhdLcMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentuxksUrfJ8yLiGfjZ8vP_45H_kVGnWYq8KwaU1C0JOhdLcMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentuxksUrfJ8yLiGfjZ8vP_45H_kVGnWYq8KwaU1C0JOhdLcMeta?.props ?? false,
    meta: paymentuxksUrfJ8yLiGfjZ8vP_45H_kVGnWYq8KwaU1C0JOhdLcMeta || {},
    alias: paymentuxksUrfJ8yLiGfjZ8vP_45H_kVGnWYq8KwaU1C0JOhdLcMeta?.alias || [],
    redirect: paymentuxksUrfJ8yLiGfjZ8vP_45H_kVGnWYq8KwaU1C0JOhdLcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengeOLhzAH6EwlwrZJffXu1argsf1armyZMxL7SlE13IM8kMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengeOLhzAH6EwlwrZJffXu1argsf1armyZMxL7SlE13IM8kMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengeOLhzAH6EwlwrZJffXu1argsf1armyZMxL7SlE13IM8kMeta?.props ?? false,
    meta: challengeOLhzAH6EwlwrZJffXu1argsf1armyZMxL7SlE13IM8kMeta || {},
    alias: challengeOLhzAH6EwlwrZJffXu1argsf1armyZMxL7SlE13IM8kMeta?.alias || [],
    redirect: challengeOLhzAH6EwlwrZJffXu1argsf1armyZMxL7SlE13IM8kMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentDWpPV7FvIleV8e7TseBuQxq6by3cDXEonqZZj25F8iMMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentDWpPV7FvIleV8e7TseBuQxq6by3cDXEonqZZj25F8iMMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentDWpPV7FvIleV8e7TseBuQxq6by3cDXEonqZZj25F8iMMeta?.props ?? false,
    meta: paymentDWpPV7FvIleV8e7TseBuQxq6by3cDXEonqZZj25F8iMMeta || {},
    alias: paymentDWpPV7FvIleV8e7TseBuQxq6by3cDXEonqZZj25F8iMMeta?.alias || [],
    redirect: paymentDWpPV7FvIleV8e7TseBuQxq6by3cDXEonqZZj25F8iMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/arenys-de-mar/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/barcelona/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/berga/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/caldes-de-montbui/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/calella/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/la-garriga/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/masquefa/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/mataro/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 10",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/sant-boi-de-llobregat/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 11",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/sant-esteve-sesrovires/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 12",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/sant-joan-despi/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 13",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/seva/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 14",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/sitges/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.name ?? "Spa by location landing: 15",
    path: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.path ?? "/pt/spas/barcelona/vallromanes/",
    props: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.props ?? false,
    meta: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta || {},
    alias: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.alias || [],
    redirect: SpaList_46pagebQ7mCed6tFhTB3V60y1_458zLiwEZ4CMIQkY1hERwViLQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/spa-senzia-montanya/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-berga-resort/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/spa-berga-resort/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/shama-spa/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/serena-spa-balmoral-hotel/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 8",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 9",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 10",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 11",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 12",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 13",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/inner-flow/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 14",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/spa-wellness-urh-ciutat-de-mataro/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 15",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 16",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/signature-organic-spa/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 17",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/signature-seventy-spa/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 18",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 19",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/balneari-broquetas/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 20",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-condal-mar/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 21",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 22",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/spa-siargao/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 23",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/balneario-vila-de-caldes/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 24",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 25",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/nyxpert-the-royal-fitness-spa/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.name ?? "Spa landing: 26",
    path: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-sant-joan-despi/",
    props: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.props ?? false,
    meta: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta || {},
    alias: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.alias || [],
    redirect: SpaDetail_46pageHdpZKP_s5W0NuZrKu_MrHEfO_nZAXfLJyH0WA_n7hLAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-massagem-relaxante-30-min-pequeno-almoco-para-2-pessoas-segunda-a-quinta-feira/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-cava-sexta-domingo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-pequeno-almoco-para-2-pessoas-segunda-a-quinta/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-massagem-relaxante-60-min-cava-segunda-a-quinta-feira/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-massagem-de-relaxamento-30-min-sofia-bar-menu-de-tapas-para-2-pessoas-sexta-domingo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-massagem-de-relaxamento-60-min-pequeno-almoco-segunda-a-quinta-feira/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-massagem-relaxante-60-min-cava-sexta-domingo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-massagem-relaxante-30-min-pequeno-almoco-para-2-pessoas-sexta-domingo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-massagem-relaxante-60-min-pequeno-almoco-sexta-domingo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-massagem-terapeutica-60-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-cava-segunda-a-quinta-feira/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-cava-2-pessoas-sexta-domingo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-pequeno-almoco-para-2-pessoas-sexta-domingo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-cava-2-pessoas-segunda-a-quinta-feira/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-massagem-de-relaxamento-30-min-sofia-bar-menu-de-tapas-para-2-pessoas-segunda-a-quinta/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-massagem-desportiva-30-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-massagem-terapeutica-30-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-massagem-desportiva-60-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-spa-sofia-bar-menu-de-tapas-para-2-pessoas-sexta-domingo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/oasis-natura-bisse/circuito-de-spa-sofia-bar-menu-de-tapas-para-2-pessoas-segunda-a-quinta-feira/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-senzia-montanya/massagem-de-tisue-profundo-20-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-senzia-montanya/ritual-hidraluronico/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-senzia-montanya/viagem-a-provenca/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-senzia-montanya/massagem-relaxante-50-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-senzia-montanya/massagem-relaxante-20-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-senzia-montanya/circuito-spa-para-criancas-4-a-17-anos/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-senzia-montanya/circuito-spa-60-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-senzia-montanya/massagem-para-um-sono-tranquilo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-senzia-montanya/rainha-do-egito-circuito/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/circuito-termico-1h30min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/spa-e-restaurante-buffet/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/anti-stress-45/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/anti-stress-25/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/spa-e-massagem-25/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/terapia-do-chocolate/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/terapia-do-chocolate-massagem-dupla/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/spa-e-massagem-45/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/spa-massagem-25-para-duas-pessoas-massagem-dupla/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/spa-massagem-45-para-duas-pessoas-massagem-dupla/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/pacote-harmonia/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/terapia-do-chocolate-massagem-consecutiva/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/spa-massagem-25-e-buffet/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/massagem-com-manteiga-de-karite-55/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/spa-massagem-25-buffet-massagem-dupla/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/spa-massagem-45-e-buffet/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/natural-spa-front-air-congress/spa-massagem-45-buffet-massagem-dupla/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-berga-resort/mime-se-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-berga-resort/massagem-expresso-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-berga-resort/ritual-lulur-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-berga-resort/massagem-e-esfoliacao-choccolat-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-berga-resort/vela-ritual-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-berga-resort/mel-magico-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-berga-resort/back-soul-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-berga-resort/escapadinha-para-casais-estadia-massagem-spa-piscina-fitness/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/shama-bodycare/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/shama-skincare/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/shama-care-para-casais/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/shama-amor-eterno-para-casais/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/spa-privado/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/massagem-descontratante/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/massagem-ceu-e-terra/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/ritual-lomi-lomi/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/ritual-de-sensualidade/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/ritual-de-alegria/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/massagem-anti-stress/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/massagem-para-criancas/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/circuito-de-agua/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/circuito-de-agua-2-criancas/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/circuito-de-agua-para-criancas/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/amor-gourmet-para-casais/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/shama-gourmet-para-casais/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/massagem-ayurvedica/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/shama-spa/massagem-ayurvedica-com-pindas/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-balmoral-hotel/ritual-de-spa-glow-flow/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-balmoral-hotel/tecido-profundo-50-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-balmoral-hotel/digital-wellness-escape/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-balmoral-hotel/massagem-de-corpo-inteiro-serenity-50-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-balmoral-hotel/massagem-de-costas-com-aromaterapia/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-balmoral-hotel/massagem-de-corpo-inteiro-serenity-80-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-balmoral-hotel/rejuvenescer/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-balmoral-hotel/ritual-de-assinatura-balmoral/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-balmoral-hotel/ritual-do-tempo-60-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-balmoral-hotel/anti-stress-para-a-cabeca-pescoco-e-ombros/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-balmoral-hotel/despertar-ritual-de-relaxamento-massagem-e-pequeno-almoco/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/breakfast-with-diamonds-edicao-de-ouro/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/glow-facial-de-recuperacao-50-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/facial-de-assinatura-organica-por-scens-40-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/experiencia-do-mes-65-minutos/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/oasis-relax-gourmet-gold-edition/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/oasis-relax-gourmet-silver-edition/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/breakfast-with-diamonds-edicao-de-prata/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/massagem-de-assinatura-25min-casais/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/oasis-relax-gourmet/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/pequeno-almoco-com-diamantes/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/desligar-para-ligar/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/acesso-a-piscina-para-adultos-90-minutos/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/cuidados-maternais-65min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/massagem-de-assinatura-25min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/massagem-de-25-minutos-para-criancas/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/bilhete-de-crianca-criancas-dos-2-aos-11-anos/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/escape-de-outono-relaxfoodie-spa-90-menu-de-almoco/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/experiencia-do-mes-40-minutos/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/elya-vital-spa/massagem-de-assinatura-50min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/spa-familiar-mascara-facial-solidaria/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/massagem-silhoutte-perfection-50-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/rainha-do-egito-por-alqvimia/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/despertar-by-serena-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/spa-tour-o-presente-perfeito/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/felicidade-das-pedras-quentes-90/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/tecido-profundo-50-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/massagem-de-costas-com-aromaterapia-30-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/esfoliacao-50min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/massagem-de-corpo-inteiro-serenity-50-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/massagem-de-corpo-inteiro-serenity-80-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/casulo-do-casal-do-serena-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/digital-wellness-escape/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/acesso-ao-spa-circuito-de-agua-50/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/tratamento-de-esculpimento-firmeza-e-tonificacao/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/ritual-do-tempo-60-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/ritual-de-assinatura-exclusivo-melia-do-serena-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sarria/massagem-anti-stress-na-cabeca-pescoco-e-ombros/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/anti-stress-para-a-cabeca-pescoco-e-ombros/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/massagem-de-costas-com-aromaterapia/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/ritual-de-assinatura-exclusivo-sir-victor-do-serena-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/juventude-eterna/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/massagem-de-corpo-inteiro-serenity-50-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-pequeno-almoco-circuito-spa-30-e-massagem-50/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/esfoliacao/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/massagem-de-tecidos-profundos-50-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/rainha-do-egito-por-alqvimia/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/slow-glow-massagem-e-circuito-de-spa-com-copo-de-cava-ou-cha/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/cityscape-spa-massagem-e-gastronomia-no-rooftop/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-pequeno-almoco-circuito-spa-30-e-massagem-30/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-pequeno-almoco-e-circuito-spa-50/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-sir-victor/spa-tour-o-presente-perfeito/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/rainha-do-egito-por-alqvimia/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/spa-tour-o-presente-perfeito/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/vip-serena-spa-ritual-de-assinatura-de-luxo-e-spa-privado-para-2/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/luz-branca/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/massagem-de-corpo-inteiro-serenity-50-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/vip-casal-cocoon-ritual-de-assinatura-spa-privado-para-2/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/festa-de-beleza-spa-privado-90/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/felicidade-das-pedras-quentes/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/entrada-spa-acesso-geral-circuito-de-agua-50/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/tratamento-de-esculpimento-firmeza-e-tonificacao/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/bem-estar-digital/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/ritual-de-assinatura-exclusivo-melia/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/massagem-anti-stress-na-cabeca-pescoco-e-ombros/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/ritual-do-tempo-60-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/massagem-de-costas-com-aromaterapia-30-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/tecido-profundo-50-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/esfoliacao/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-sky/casulo-do-casal-do-serena-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/spa-tour-o-presente-perfeito/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/sabores-mediterranicos-90/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/viagem-de-assinatura/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/esfoliante-corporal-e-de-laranja-doce/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/rejuvenescer-60-rejuvenescer-60-rejuvenescer-60-rejuvenescer-60-rejuvenescer-60-rejuvenescer-60/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/aromaterapia-30-aromaterapia-30-aromaterapia-30-aromaterapia-30-aromaterapia-30-aromaterapia-30/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/massagem-anti-stress-na-cabeca-pescoco-e-ombros/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/massagem-de-corpo-inteiro-serenity-50/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/awakening-by-serena-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/aromaterapia-50-aromaterapia-50-aromaterapia-50-aromaterapia-50-aromaterapia-50-aromaterapia-50/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/ritual-do-tempo-60/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/massagem-de-tecidos-profundos-50/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/experiencia-gastro-wellness-spa-50-com-massagem-opcional-e-jantar-para-2-pessoas/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/grums-spa-mediterranean-wellness/acesso-ao-spa-circuito-de-agua-50/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/spazio-sunrise-pequeno-almoco-buffet-circuito-termal-massagem-de-aromaterapia-40min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/sri-lanka-30min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 169",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/peeling-com-hidratacao/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 170",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/aromaterapia-50min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 171",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/defesa-da-idade-50min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 172",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/mediterranean-bliss-120-spazio-para-1/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 173",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/milagre-do-oceano-50min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 174",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/sensacoes-50min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 175",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/higiene-ativa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 176",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/cacau-tamakuru-90min-spazio-para-2/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 177",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/mediterranean-bliss-120-spazio-para-2/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 178",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/infinito-60min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 179",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/twin-bombay-80min-spazio-para-2/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 180",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/segredos-do-nilo-90min-spazio-para-1/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 181",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/tecido-profundo-50min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 182",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/desintoxicacao-de-bambu-80min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 183",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/spazio-sunrise-pequeno-almoco-buffet-circuito-termal/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 184",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/spazio-celebrate-spa-privado/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 185",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-wellness/tibete-holistico-90min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 186",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/inner-flow/dia-no-inner-flow/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 187",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/inner-flow/bem-vindo-a-sua-primeira-experiencia-de-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 188",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/inner-flow/spa-massagem-para-casais/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 189",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/inner-flow/primeira-vez-primeira-massagem/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 190",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/inner-flow/primeira-experiencia-como-casal/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 191",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/inner-flow/spa-massagem/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 192",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/circuito-spa-adulto/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 193",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/massagem-de-aromaterapia-25-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 194",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/massagem-de-aromaterapia-25-min-duplo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 195",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/ritual-da-imperatriz-kobido/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 196",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/ritual-de-flash/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 197",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/massagem-de-relaxamento-shi-zen/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 198",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/circuito-spa-infantil-4-a-15-anos/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 199",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/experiencia-de-relaxamento-115-min-duplo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 200",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/experiencia-de-relaxamento-140-min-duplo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 201",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/experiencia-de-relaxamento-115-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 202",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/pais-e-filhos/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 203",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/luz-do-mediterraneo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 204",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/experiencia-mediterranica/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 205",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/experiencia-de-relaxamento-140-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 206",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-nature-ilunion-les-corts/experiencia-junior-relax/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 207",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/signature-organic-spa/massagem-de-relaxamento-30min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 208",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/signature-organic-spa/massagem-de-relaxamento-50min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 209",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/signature-organic-spa/massagem-do-amor-50min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 210",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/signature-organic-spa/ritual-de-serenidade-e-vitalidade-55min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 211",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/signature-seventy-spa/massagem-de-relaxamento-30min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 212",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/signature-seventy-spa/massagem-de-relaxamento-50min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 213",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/signature-seventy-spa/massagem-do-amor-50min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 214",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/signature-seventy-spa/ritual-de-serenidade-e-vitalidade-55min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 215",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/pure-elegance-por-alqvimia/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 216",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/terapia-lomi-lomi/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 217",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/massagem-personalizada/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 218",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/majestic-afterwork-massagem-cocktail/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 219",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/massagem-facial-kobido/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 220",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/majestic-morning-up-pequeno-almoco-zona-de-hidroterapia-massagem/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 221",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/momentos-amigaveis-do-duo-majestoso/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 222",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/momento-quierete-1/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 223",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/zona-de-hidroterapia-tratamento-facial-anti-envelhecimento-jantar/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 224",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/energia-diamante-de-natura-bisse/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 225",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/toque-oriental-tratamento-de-assinatura/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 226",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/flash-facial/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 227",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/cocktail-de-vitamina-c-de-natura-bisse/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 228",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/a-cura-de-natura-bisse-1/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 229",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/zona-de-hidroterapia/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 230",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/momento-romantico/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 231",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/pacote-majestic-morning-up/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 232",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/majestic-spa-2/toque-de-calma-por-alqvimia/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 233",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/balneari-broquetas/experiencia-termica/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 234",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/balneari-broquetas/experiencia-relaxante-e-termal-para-2/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 235",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-condal-mar/tempo-ritual-120-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 236",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-condal-mar/ritual-do-tempo-90-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 237",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-condal-mar/tempo-ritual-60-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 238",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-condal-mar/massagem-de-corpo-inteiro-serenity/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 239",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-condal-mar/massagem-desportiva/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 240",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-condal-mar/massagem-de-desintoxicacao/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 241",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-condal-mar/massagem-de-tecidos-profundos/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 242",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/serena-spa-melia-condal-mar/digital-wellness-escape/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 243",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/relaxamento-e-almoco-gastro-de-segunda-a-sexta-feira/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 244",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/kobido-ou-lifting-facial-japones/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 245",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/momentos-compreensivos-adulto-crianca-6-13-anos/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 246",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/spa-privado-3/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 247",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/circuito-de-spa-termal-de-segunda-a-quinta-feira-feriados-nao-incluidos-2/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 248",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/spa-e-jantar-gastronomico-de-segunda-a-sexta-feira-a-noite/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 249",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/circuito-de-spa-termal-120-min-sexta-feiras-sabados-domingos-e-feriados/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 250",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/banho-de-hidromassagem-envolvimento-de-algas-circuito-termal/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 251",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/spa-privado-de-luxo/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 252",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/escapadela-termal/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 253",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/fim-de-semana-spa-gastro/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 254",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/massagem-abhyanga/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 255",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/fins-de-semana-e-feriados-relaxantes-e-gastro/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 256",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/massagem-de-relaxamento-para-casais/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 257",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/spa-privado-2/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 258",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/circuito-termal-sextas-sabados-domingos-e-feriados-1/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 259",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/circuito-de-spa-termal-de-segunda-a-quinta-feira-feriados-nao-incluidos-1/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 260",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/almoco-spa-e-gastro-de-segunda-a-sexta-feira-ao-meio-dia/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 261",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/spa-e-pequeno-almoco-de-segunda-a-sexta-feira-feriados-nao-incluidos/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 262",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/spa-e-pequeno-almoco-sabados-domingos-e-feriados/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 263",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/spa-privado-de-luxo-1/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 264",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/spa-privado-1/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 265",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/50-drenagem-linfatica-circuito-termico/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 266",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/momentos-compreensivos-adulto-crianca-6-13-anos-1/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 267",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/blancafort-spa-termal/massagem-subaquatica-massagem-redutora-localizada-circuito-termico/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 268",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-siargao/massagem-de-relaxamento-30min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 269",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-siargao/massagem-de-relaxamento-50min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 270",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-siargao/massagem-do-amor-50min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 271",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-siargao/ritual-de-serenidade-e-vitalidade-55min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 272",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/balneario-vila-de-caldes/experiencia-privada-aquae-para-2-pessoas/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 273",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/balneario-vila-de-caldes/experiencia-romantica-privada-para-2/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 274",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/balneario-vila-de-caldes/experiencia-termal-privada-para-2/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 275",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/balneario-vila-de-caldes/experiencia-termica/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 276",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/balneario-vila-de-caldes/massagem-geral/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 277",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/pack-relax-health-privee-circuito-privado-massagem-60-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 278",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/pack-relax-health-privee-circuito-privado-massagem-30-min/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 279",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/circuito-de-aguas-massagem-relaxante-30/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 280",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/circuito-de-agua-massagem-profunda/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 281",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/circuito-de-aguas-massagem-de-relaxamento-60/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 282",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/circuito-de-agua/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 283",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/ritual-de-oxigenio/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 284",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/ritual-go-sun-maracuja/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 285",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/massagem-relaxante-e-saudavel-30/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 286",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/massagem-relax-saude-60/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 287",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/circuito-privado/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 288",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/cromoterapia-sessao-de-massagem/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 289",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/massagem-profunda/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 290",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/sessao-de-reiki-massagem/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 291",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spa-masia-vallromanes/rituais-da-deusa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 292",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-sant-joan-despi/mime-se-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 293",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-sant-joan-despi/massagem-expresso-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 294",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-sant-joan-despi/ritual-lulur-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 295",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-sant-joan-despi/massagem-e-esfoliacao-choccolat-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 296",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-sant-joan-despi/vela-ritual-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 297",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-sant-joan-despi/mel-magico-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 298",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-sant-joan-despi/acesso-ao-spa-para-2-pessoas/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.name ?? "Service landing: 299",
    path: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.path ?? "/pt/spas/barcelona/spazio-nyxpert-sant-joan-despi/back-soul-acesso-ao-spa/",
    props: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.props ?? false,
    meta: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta || {},
    alias: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.alias || [],
    redirect: ServiceDetail_46pageZJuunrSYZFV7unyDim6EOuvqD9e39MgGi1hVVxGQkKAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-74f99564-c812-4511-ac18-73b196d301ee/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]