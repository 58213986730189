<template>
  <div />
</template>

<script lang="ts">
import PlausibleAnaliticsEventBus from '~/core/events/plausible'
import CookiesRepository from '~/core/ts/repository/CookiesRepository'

export type PlausibleWindow = Window & {
  plausible: (eventName: string, payload: Record<string, any>) => void,
}

type PlausibleEvent = {
  revenue: {
    amount: number,
    currency: 'EUR',
  } | undefined,
  props: {
    purchase_id: string,
    voucher_id: string,
  }
}

export default defineNuxtComponent({
  setup() {
    const runtimeConf = useRuntimeConfig()

    // NOTE: Antes esto estaba en: nuxt.config.ts
    // {
    //   defer: true,
    //   'data-domain': removeProtocolFromUrl(config.runtime.domain || 'www.spalopia.com'),
    //   type: 'text/javascript',
    //   // src: 'https://plausible.io/js/script.js',
    //   src: 'https://plausible.io/js/script.revenue.js',
    //   skip: config.runtime.environment !== 'prod'
    // },
    function installPlausible() {
      const plausibleScript = document.createElement('script')
      plausibleScript.setAttribute('src', 'https://plausible.io/js/script.revenue.js')
      plausibleScript.setAttribute('defer', 'defer')
      plausibleScript.setAttribute('type', 'text/javascript')
      document.body.appendChild(plausibleScript)
    }

    onMounted(() => {
      if (runtimeConf.public.isDev) {
        return
      }

      installPlausible()

      const win = window as any
      if (!win.plausible) {
        win.plausible = win.plausible || function() { (win.plausible.q = win.plausible.q || []).push(arguments) }
      }
    })

    PlausibleAnaliticsEventBus.on((name: string, meta: PlausibleEvent) => {
      const klaroUserConfig = CookiesRepository.getCookiesAsObject('klaro')
      if (!klaroUserConfig['plausible']) {
      // if (!klaroUserConfig['google-analytics']) {
        console.warn('User declined Plausible Analitics')

        // NOTE: Esto no puede ser muy legal.
        // return
      }

      const win = window as unknown as PlausibleWindow

      if (!win.plausible) {
        console.warn('Plausible is not enabled')
        return
      }

      win.plausible(name, meta)

      const blockers = CookiesRepository.getCookiesAsObject('blockers')
      if (blockers.enabled) {
        const { purchase_id, voucher_id } = meta.props
        if (!purchase_id) {
          return
        }

        const cookiesEnabled = !!klaroUserConfig['google-analytics']

        const adblockEvent = {
          name,
          purchase_id,
          voucher_id,

          cookies_accepted: cookiesEnabled,
          adblock_enabled: true,
        }

        win.plausible('Event with adblock', adblockEvent)
      }
    })

    return {}
  },
})
</script>
