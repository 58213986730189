<template>
  <div>
    <GeneralLoading />
    <GeneralNotificator />
    <GeneralLogger />

    <ClientOnly>
      <GeneralKlaroConfigDialog />
    </ClientOnly>

    <ClientOnly v-if="externalDeps">
      <GeneralGA4Analitics />
      <GeneralPlausible />
      <GeneralAdBlockDetector />

      <GeneralConnectif />
      <GeneralAwin />
    </ClientOnly>

    <Sentry />

    <!-- TODO: Cambiar esto por un nuxt-page para que los layout vayan dentro de cada página y así poder pasarles parámetros -->
    <nuxt-layout />
  </div>
</template>

<script lang="ts">
import GeneralLoading from '~/core/components/singleton/GeneralLoading.vue'
import GeneralNotificator from '~/core/components/singleton/GeneralNotificator.vue'
import GeneralLogger from './core/components/singleton/GeneralLogger.vue'

import GeneralGA4Analitics from '~/core/components/singleton/GeneralGA4Analitics.vue'
import GeneralPlausible from './core/components/singleton/GeneralPlausible.vue'
import GeneralAdBlockDetector from './core/components/singleton/GeneralAdBlockDetector.vue'

import GeneralKlaroConfigDialog from './core/components/singleton/GeneralKlaroConfigDialog.vue'
import GeneralConnectif from './core/components/singleton/GeneralConnectif.vue'
import GeneralAwin from './core/components/singleton/GeneralAwin.vue'

import Sentry from '~/core/components/singleton/Sentry.vue'

import { useFetchProxy } from './core/composable/shared/useFetchProxy'
import { useSessionLocation } from './core/composable/shared/useSessionLocation'
import { useContext } from './core/composable/shared/useContext'
import { useLangSelector } from './core/composable/shared/useLangSelector'

import type { Location } from './core/ts/entity/Location'
import HeadMetaBuilder from './core/ts/util/HeadMetaBuilder'
import { joinPaths } from './core/ts/util/string'
import { hreflangDict } from './core/ts/lang-config'
import CookiesRepository from './core/ts/repository/CookiesRepository'

export default defineNuxtComponent({
  components: {
    GeneralLoading,
    GeneralNotificator,
    GeneralLogger,
    GeneralGA4Analitics,
    GeneralPlausible,
    GeneralAdBlockDetector,
    GeneralKlaroConfigDialog,
    GeneralConnectif,
    GeneralAwin,
    Sentry,
  },
  async setup() {
    const route = useRoute()

    const context = useContext()
    const runtime = useRuntimeConfig()

    const externalDeps = ref(false)

    const {
      generateLangMenuItems
    } = useLangSelector()

    function checkNoDepsMode() {
      if (runtime.public.isLocal) {
        return
      }

      const noDepsCookie = CookiesRepository.getCookiesAsText('spl-no-external-deps-mode')
      if (noDepsCookie) {
        return
      }

      const noExternalDepsMode = route.query['spl-no-external-deps-mode'] as string || null
      if (noExternalDepsMode) {
        CookiesRepository.addCookieExpiredInHours('spl-no-external-deps-mode', 1, 1)
        return
      }

      externalDeps.value = true
    }

    onMounted(() => {
      checkNoDepsMode()

      if (currentLocationBasedOnURL) {
        sessionLocation.storeLocationAndCountry(currentLocationBasedOnURL)
        return
      }

      // No estamos en una landing con localización.
      sessionLocation.goToStoredCountryOrQueryCountry()
    })

    async function fetchLangMenuItems() {
      switch (context.type.value) {
        case 'dynamic':
        case 'redirections':
          return []
      }

      const urlsByLang = await useFetchProxy<Record<string, string>>(
        '/api/shared/get-lang-alter-urls-by-uri',
        {
          // server: false,
          method: 'post', body: { uri: route.path }
        }
      )

      return generateLangMenuItems(urlsByLang)
    }

    async function getCurrentLocation(): Promise<Location | null> {
      switch (context.type.value) {
        case 'dynamic':
        case 'homes':
        case 'redirections':
          return null
      }

      // TODO: useFetchProxy. Añadir ésto de la clave y la respuesta en objeto para todas las llamadas.
      const response = await useFetchProxy<{ ok: boolean, location: Location | null }>('/api/shared/get-location-for-uri', {
        method: 'post',
        body: { uri: route.path },
        key: `app-${route.path}`
      })

      return response.location
    }

    async function getAllLocations(): Promise<Array<Location>> {
      switch (context.type.value) {
        case 'dynamic':
        case 'redirections':
          return []
      }

      const response = await useFetchProxy<{ok: boolean, locations: Array<Location>}>(
        '/api/locations/location-list-with-any-landing',
        {}
      )
      return response.locations
    }

    const currentLocationBasedOnURL = await getCurrentLocation()
    const allLocations = await getAllLocations()

    const alternates = await fetchLangMenuItems()
    const builder = HeadMetaBuilder.create()
    builder.withTitleAndMetaTitle('Spalopia')

    alternates
      .forEach((base) => {
        if (!base.children) {
          return
        }
        base.children.forEach((urlLang) => {
          if (!urlLang.lang) {
            return
          }

          const url = joinPaths(runtime.public.domain, urlLang.url)
          const hreflang = hreflangDict[urlLang.lang]
          builder.withLink('alternate', url, hreflang)
        })
      })
    useHead(builder.get())

    const sessionLocation = useSessionLocation(allLocations)

    return {
      allLocations,

      country: sessionLocation.storedLocationCountry,

      externalDeps,
    }
  },
})
</script>

<style lang="scss">
// Util SCSS clases
.util-snippet {
  @apply h-0;
  @apply opacity-0;
  display: none;
}

.only-mobile {
  @screen lg {
    @apply hidden;
  }
}

.only-desktop-block {
  @apply hidden;
  @screen lg {
    @apply block;
  }
}

.cm-body {
  display: none;
}

// .cn-decline, .cm-btn-decline {
//   display: none !important;
// }

.cm-btn-accept {
  // display: none;
  background-color: #557f9c !important;
  color: white;
}

.cm-btn-accept-all {
  background: #4d9907 !important;
  color: white;
}

.cm-powered-by a {
  display: hidden !important;
}

.cm-footer-buttons {
  justify-content: end !important;
}

</style>
