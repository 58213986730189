import EventBus from './event-bus'

export default class NotificationEventBus {
  static on(func: any): number {
    return EventBus.on('notification', func)
  }

  static off(id: number) {
    return EventBus.off('notification', id)
  }

  static show(title: string, message: string) {
    EventBus.emit('notification', title, message)
  }
}
