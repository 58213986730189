<template>
  <div class="confirmation">
    <div v-if="externalDeps">
      <TeTePixel
        :get-away-purchase="getAwayPurchase"
        :should-display="isConfirmed"
      />
      <FloodLightPixel
        :should-display="isConfirmed"
        is-confirmation
      />
      <AdformPixel
        :should-display="isConfirmed"
        is-confirmation
      />
      <AwinPixel
        :should-display="isConfirmed"
        :get-away-purchase="getAwayPurchase"
      />
    </div>

    <Card
      v-if="!getAwayPurchase && !existsError"
      mobile-with-border
      desktop-with-border
      class="confirmation__loading-data"
    >
      <h2><DynLang by-key="downloading-booking-data" />...</h2>
      <LoadingSpinner />
    </Card>

    <Card
      v-if="existsError"
      mobile-with-border
      desktop-with-border
      class="confirmation__error"
    >
      <h2>
        <DynLang
          by-key="were-sorry"
          capitalize-first
        />
      </h2>
      <p>
        <DynLang
          by-key="an-error-has-ocurred-contact-support"
          capitalize-first
        />:
      </p>
      <div class="confirmation__error__email">
        <a href="mailto:ayuda@spalopia.com">ayuda@spalopia.com</a>
      </div>
      <p class="confirmation__error__sad">
        <DynLang
          by-key="apologise-for-inconvenience"
          capitalize-first
        />
      </p>
    </Card>

    <div
      v-else
      class="confirmation__final"
    >
      <div>
        <div v-if="getAwayPurchase && isConfirmed">
          <h1 class="confirm-card__title only-desktop-block">
            <DynLang
              by-key="congratulations-XX"
              :params="{ client: getAwayPurchase.buyer.name }"
            />
          </h1>

          <h3 class="confirm-card__subtitle only-desktop-block">
            <DynLang
              by-key="booking-get-your-bags-ready"
              capitalize-first
            />:
          </h3>
        </div>

        <Card
          v-if="getAwayPurchase"
          mobile-with-border
          desktop-with-border
          class="confirm-card"
        >
          <div v-if="getAwayPurchase && isConfirmed">
            <h1 class="confirm-card__title only-mobile">
              <DynLang
                by-key="congratulations-XX"
                :params="{ client: getAwayPurchase.buyer.name }"
              />
            </h1>

            <h3 class="confirm-card__subtitle only-mobile">
              <DynLang
                by-key="booking-get-your-bags-ready"
                capitalize-first
              />:
            </h3>
          </div>

          <hr class="only-mobile">

          <div
            v-if="isConfirmed"
            class="confirm-card__content"
          >
            <GetAwayPurchaseSummary
              :availability="getAwayPurchase.getAwayAvailability"
              :rate="selectedRate"
              :get-away="getAway"
              show-get-away
            />
          </div>

          <div
            v-if="isConfirming"
            class="confirm-card__content"
          >
            <span class="confirm-card__content__waiting-msg">
              <p>
                <DynLang
                  by-key="confirming-booking"
                  capitalize-first
                />
              </p>
              <p>
                <DynLang
                  by-key="voucher-confirm-takes-a-minute"
                  capitalize-first
                />
              </p>
              <p class="thanks">
                <DynLang
                  by-key="thanks"
                  capitalize-first
                />
              </p>
            </span>
            <LoadingSpinner />
          </div>
        </Card>
      </div>
    </div>

    <div
      v-if="isConfirmed"
      class="confirm-card__conditions"
    >
      <DynLang
        by-key="see"
        capitalize-first
      />
      <SplNuxtLink
        class="confirm-card__conditions__link"
        is-external
        is-target-blank
        no-lang
        to="/condiciones-generales-compra"
      >
        <DynLang
          by-key="hotel-spa-terms-and-conditions"
          capitalize-first
        />
      </SplNuxtLink>
    </div>
  </div>
</template>

<script lang="ts">
import GetAwayPurchaseSummary from '~/core/components/get-away/GetAwayPurchaseSummary.vue'

import TeTePixel from '~/core/components/slapped-pixels/TeTe.pixel.vue'
import FloodLightPixel from '~/core/components/slapped-pixels/Floodlight.pixel.vue'
import AdformPixel from '~/core/components/slapped-pixels/Adform.pixel.vue'
import AwinPixel from '~/core/components/slapped-pixels/Awin.pixel.vue'

import SplNuxtLink from '~/core/components/shared/SplNuxtLink.vue'
import LoadingSpinner from '~/core/components/shared/LoadingSpinner.vue'
import Card from '~/core/components/shared/Card.vue'

import { useGetAwayPurchaseConfirmation } from '~/core/composable/get-away/useGetAwayPurchaseConfirmation'
import { GetAwayPurchase } from '~/core/ts/entity/GetAwayPurchase'

definePageMeta({
  layout: 'thanks',
})

export default defineNuxtComponent({
  components: {
    GetAwayPurchaseSummary,
    // Píxeles de afiliados
    TeTePixel,
    FloodLightPixel,
    AdformPixel,
    AwinPixel,

    SplNuxtLink,
    LoadingSpinner,
    Card,
  },
  setup() {
    const runtime = useRuntimeConfig()

    const {
      getAwayPurchase,
      existsError,
      isConfirming,
      isConfirmed,
    } = useGetAwayPurchaseConfirmation()

    const selectedRate = computed(() => {
      if (!getAwayPurchase.value) {
        return null
      }

      return GetAwayPurchase.getSelectedRate(getAwayPurchase.value)
    })

    const getAway = computed(() => {
      if (!getAwayPurchase.value) {
        return null
      }

      return getAwayPurchase.value.getAwayAvailability.getAway
    })

    const externalDeps = computed(() => {
      if (runtime.public.isLocal) {
        return false
      }

      if (runtime.public.isDev) {
        return false
      }

      return true
    })

    return {
      getAwayPurchase,
      selectedRate,
      getAway,

      existsError,
      isConfirming,
      isConfirmed,

      externalDeps,
    }
  }
})
</script>

<style lang="scss" scoped>
.confirmation {
  @apply mx-2;
  @apply flex;
  @apply items-center justify-center;
  flex-direction: column;

  @screen lg {
    margin-top: 12vh;

    @apply p-8;
    @apply mb-3;
    @apply mx-20;
  }

  h2 {
    @apply text-3xl;
    @apply text-green-900;
  }

  &__loading-data {
    @apply py-8 px-12;
    @apply w-2/3;

    @screen lg {
      @apply w-1/3;
    }

    h2 {
      @apply py-4;
      @apply text-xl;
      @apply font-semibold;

      @apply text-center;
    }
  }

  &__error {
    @apply text-center;
    @apply py-6;
    @apply px-8;

    @apply my-8;

    @screen lg {
      @apply mx-12;
      @apply my-2;
    }

    h2 {
      @apply mb-4;
    }

    &__sad {
      @apply text-spl-primary-dark;
    }

    p {
      @apply text-xl;
      @apply mt-2;
    }

    &__email {
      @apply text-xl;
      @apply font-semibold;
      @apply text-green-900;
      @apply mt-2;
      @apply mb-4;
    }
  }

  &__final {
    @screen md {
      @apply w-4/6;
    }

    @screen lg {
      @apply w-1/2;
    }

    @screen xl {
      @apply w-1/3;
    }
  }
}

.confirm-card {
  @apply px-4;

  @screen lg {
    @apply mt-6;
    @apply p-8;
  }

  &__title {
    @apply text-3xl;
    @apply font-bold;
    @apply text-center;

    @apply my-30p;

    @screen lg {
      @apply text-white;
      text-shadow: -1px -1px 0 #222, 1px -1px 0 #222, -1px 1px 0 #222, 1px 1px 0 #222;
    }
  }

  &__subtitle {
    @apply text-center;
    @apply my-2;

    @apply text-xl;

    @apply text-spl-dark;

    @screen lg {
      @apply text-white;
      @apply text-2xl;
    }
  }
  &__conditions {
    @apply block;
    @apply px-9;
    @apply text-spl-dark;
    @apply mt-6;
    @apply mb-6;

    @apply text-base;
    @screen lg {
      @apply text-center;
      @apply mb-2;
    }

    &__link {
      @apply underline;
    }
  }

  &__content {
    @apply p-6;

    &__waiting-msg {
      @apply text-2xl font-bold text-spl-dark;
      @apply text-center;

      p {
        @apply my-1;
        @apply text-xl;
      }

      .thanks {
        @apply text-spl-primary-dark;
        @apply pt-2;
      }
    }
  }
}



</style>
