<template>
  <div>
    <Breadcrumb />

    <div class="spa-list__header">
      <div>
        <h1 class="spa-list__title">
          <Lang
            v-if="location"
            by-key="XX-in-XX"
            :params="{
              left: 'Spas',
              right: location ? location.label : '',
            }"
          />

          <span v-else-if="storedLocationCountry">
            <DynLang
              by-key="XX-in-XX"
              :params="{
                left: 'Spas',
                right: '',
              }"
            />
            <span>{{ storedLocationCountry.label }}</span>
          </span>

          <span v-else>Spas</span>
        </h1>

        <SpaListSmallDescription :location="location" />
      </div>
    </div>

    <div class="spa-list__list-wrapper">
      <!-- Filtros -->
      <div>
        <!-- Filtros de escritorio -->
        <div class="only-desktop-block">
          <Card
            desktop-with-border
            class="spa-list__filters"
          >
            <div class="spa-list__filters__title">
              <Lang
                by-key="filter-by"
                capitalize-first
              />
            </div>

            <div
              v-if="filterLocationsTree.length"
              class="spa-list__filters__expanded-filter"
            >
              <p class="spa-list__filters__expanded-filter__header">
                <Lang
                  by-key="region"
                  capitalize-first
                />
              </p>

              <LocationLinkTree
                :link-url-base="locationFilterBaseUrl"
                :countries="countries"
                :general-parent-location="generalParentLocation"
                :locations-tree="filterLocationsTree"
                :current-location="location"
              />
            </div>

            <hr>

            <div
              v-if="multiPromoOptions.length"
              class="spa-list__filters__expanded-filter"
            >
              <p class="spa-list__filters__expanded-filter__header">
                <Lang
                  by-key="experience"
                  capitalize-first
                />
              </p>

              <TruncatedList
                :split-at="7"
                :data="multiPromoOptions"
                expand-button-left
              >
                <template #default="{ element }">
                  <div
                    class="spa-list__filters__expanded-filter__checkbox-node"
                    :class="{'selected': element.value}"
                  >
                    <input
                      :id="`category-checkbox-${element.id}`"
                      v-model="element.value"
                      type="checkbox"
                      @change="onPromoCategoryNodeClick"
                    >
                    <label :for="`category-checkbox-${element.id}`">
                      <Lang
                        :by-raw="element.texts"
                      />
                    </label>
                  </div>
                </template>
              </TruncatedList>
            </div>
          </Card>
        </div>

        <!-- Filtros de móvil -->
        <div class="only-mobile">
          <div class="spa-list__mobile-filters">
            <StickyHeader>
              <template #only-sticky>
                <div class="spa-list__mobile-filters__header">
                  <h1 class="spa-list__mobile-filters__header__title">
                    <Lang
                      v-if="location"
                      by-key="XX-in-XX"
                      :params="{
                        left: 'Spas',
                        right: location ? location.label : '',
                      }"
                    />

                    <span v-else-if="storedLocationCountry">
                      <DynLang
                        by-key="XX-in-XX"
                        :params="{
                          left: 'Spas',
                          right: '',
                        }"
                      />
                      <span>{{ storedLocationCountry.label }}</span>
                    </span>

                    <span v-else>Spas</span>
                  </h1>

                  <div class="spa-list__mobile-filters__header__location-selector">
                    <SessionLocationSelector dark />
                  </div>
                </div>
              </template>
              <template #body>
                <div class="spa-list__mobile-filters__header">
                  <div class="spa-list__mobile-filters__header__controls">
                    <button
                      class="spa-list__mobile-filters__button"
                      @click="setIsModalDisplayed(true)"
                    >
                      <HopperIcon
                        class="spa-list__mobile-filters__button__icon"
                        :size="18"
                      />
                      <span><Lang
                        by-key="filter"
                        capitalize-first
                      /></span>
                    </button>

                    <SelectBox
                      id="spa-list-sorter"
                      variant="minified"
                      :data="sortOptions"
                      display-prop="label"
                      class="spa-list__mobile-filters__sort-selector"
                      @input="pushSortOrder"
                    >
                      <template #label>
                        <Lang
                          by-key="sort-by"
                          capitalize-first
                        />: <span class="spa-list__mobile-filters__sort-selector__label">{{ selectedSortOptionLabel }}</span>
                      </template>
                    </SelectBox>
                  </div>

                  <div
                    v-if="selectedPromos.length"
                    class="spa-list__mobile-selected-filters"
                  >
                    <Badge
                      v-for="category in selectedPromos"
                      :key="category.id"
                      class="spa-list__mobile-selected-filters__category"
                    >
                      {{ category.label }}
                    </Badge>
                  </div>
                </div>
              </template>
            </StickyHeader>

            <ConfigModalMobile
              v-model="isModalDisplayed"
              is-apply-button-displayed
            >
              <template #header>
                <div
                  class="spa-list__mobile-filters__header"
                >
                  <Lang
                    by-key="filter-by"
                    capitalize-first
                  />
                </div>
              </template>

              <div
                class="spa-list__mobile-filters__expanded-filter"
              >
                <p class="spa-list__mobile-filters__expanded-filter__header">
                  <Lang
                    by-key="region"
                    capitalize-first
                  />
                </p>
                <LocationLinkTree
                  :link-url-base="locationFilterBaseUrl"
                  :countries="countries"
                  :general-parent-location="generalParentLocation"
                  :locations-tree="filterLocationsTree"
                  :current-location="location"
                />
              </div>

              <hr>

              <div
                class="spa-list__mobile-filters__expanded-filter"
              >
                <p class="spa-list__mobile-filters__expanded-filter__header">
                  <Lang
                    by-key="experience"
                    capitalize-first
                  />
                </p>

                <TruncatedList
                  :split-at="7"
                  :data="multiPromoOptions"
                  expand-button-left
                >
                  <template #default="{ element }">
                    <div
                      class="spa-list__filters__expanded-filter__checkbox-node"
                      :class="{'selected': element.value}"
                    >
                      <input
                        :id="`category-checkbox-${element.id}`"
                        v-model="element.value"
                        type="checkbox"
                        @change="onPromoCategoryNodeClick"
                      >
                      <label :for="`category-checkbox-${element.id}`">
                        <Lang
                          :by-raw="element.texts"
                          capitalize-all-large
                        />
                      </label>
                    </div>
                  </template>
                </TruncatedList>
              </div>
            </ConfigModalMobile>
          </div>
        </div>
      </div>

      <div
        v-if="spasView.length"
        class="spa-list__spas"
      >
        <div class="spa-list__spas__header">
          <div>
            <h2 class="spa-list__spas__header__subtitle">
              {{ subtitle }}
            </h2>
          </div>

          <div>
            <SelectBox
              id="spa-list-sorter"
              variant="minified"
              :data="sortOptions"
              display-prop="label"
              class="spa-list__spas__header__sort-selector"
              @input="pushSortOrder"
            >
              <template #label>
                <Lang
                  by-key="sort-by"
                  capitalize-first
                />: <span class="spa-list__spas__header__sort-selector__label">{{ selectedSortOptionLabel }}</span>
              </template>
            </SelectBox>
          </div>
        </div>

        <TruncatedList
          :split-at="7"
          :data="spasView"
        >
          <template #default="{ element, index }">
            <div class="spa-list__item">
              <BenefitsCard
                v-if="index === 3"
                :tags="['SAVE_TIME', 'GIVE_EXPERIENCIES', 'VALID_BONUSES']"
                :can-close="true"
                :is-title-displayed="false"
                class="spa-list__benefits"
              />
              <SpaListItem
                :spa="element"
                :selected-promos="selectedPromos"
              />
            </div>
          </template>
        </TruncatedList>
      </div>

      <div v-if="!spasView.length">
        <div class="spa-list__no-spas">
          <p class="spa-list__no-spas__header">
            <Lang by-key="no-available-spas" />
          </p>
          <p>
            <Lang by-key="no-available-spas-secondary" />
          </p>
        </div>
      </div>

    <!-- End: list__wrapper -->
    </div>

    <SpaListLargeDescription :location="location" />
  </div>
</template>

<script lang="ts">
import { SpaLandingByLocation } from '~/core/ts/entity/SpaLandingByLocation'

import Breadcrumb from '~/core/components/shared/Breadcrumb.vue'
import TruncatedList from '~/core/components/shared/TruncatedList.vue'
import BenefitsCard from '~/core/components/spa/BenefitsCard.vue'

import SpaListSmallDescription from '~/core/components/spa/atom/SpaListSmallDescription.vue'
import SpaListLargeDescription from '~/core/components/spa/atom/SpaListLargeDescription.vue'
import SpaListItem from '~/core/components/spa/SpaListItem.vue'
import ConfigModalMobile from '~/core/components/shared/form/ConfigModalMobile.vue'
import SelectBox from '~/core/components/shared/form/SelectBox.vue'
import LocationLinkTree from '~/core/components/location/LocationLinkTree.vue'
import SplNuxtLink from '~/core/components/shared/SplNuxtLink.vue'
import Badge from '~/core/components/shared/Badge.vue'
import Card from '~/core/components/shared/Card.vue'
import StickyHeader from '~/core/components/shared/StickyHeader.vue'
import SessionLocationSelector from '~/core/components/menu/session-location/SessionLocationSelector.vue'

import HopperIcon from '~/core/components/shared/icons/Hopper.icon.vue'

import { SpaMiniList } from '~/core/ts/entity/Spa'
import { ServiceCollection } from '~/core/ts/entity/Service'
import { Location, type LocationTree } from '~/core/ts/entity/Location'
import { capitalizeAllWords, capitalizeFirstWord, joinPaths, slashUri } from '~/core/ts/util/string'
import { translateByKey } from '~/core/ts/util/translate'

import { useSorter } from '~/core/composable/spa/useSorter'
import { useMultiPromoFilter } from '~/core/composable/spa/useMultiPromoFilter'
import { useSessionLocation } from '~/core/composable/shared/useSessionLocation'

import { getSpaListPageMetadata } from '~/core/composable/spa/useSpaListPageHeader'

import { CheckBoxNode } from '~/core/ts/entity/CheckBoxNode'
import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import { Promotion } from '~/core/ts/entity/Promotion'

export default defineNuxtComponent({
  components: {
    Breadcrumb,
    TruncatedList,
    BenefitsCard,
    SpaListItem,
    SpaListSmallDescription,
    SpaListLargeDescription,
    ConfigModalMobile,
    SelectBox,
    LocationLinkTree,
    SplNuxtLink,
    Badge,
    Card,
    StickyHeader,
    SessionLocationSelector,
    HopperIcon,
  },
  async setup() {
    const route = useRoute()

    const uri = route.path
    const runtimeConfig = useRuntimeConfig()
    const domain = runtimeConfig.public.domain

    const isModalDisplayed = ref(false)
    function setIsModalDisplayed(value: boolean) {
      isModalDisplayed.value = value
    }

    const recommendedText = capitalizeAllWords(translateByKey('recommended', true))
    const mostValuedText = capitalizeAllWords(translateByKey('most-valued', true))
    const cheapestServiceText = capitalizeAllWords(translateByKey('cheapest-service'))
    const mostExpensiveServiceText = capitalizeAllWords(translateByKey('most-expensive-service'))

    const {
      sortOrder,
      pushSortOrder,
      sortOptions,
      selectedSortOptionLabel
    } = useSorter([
      { label: recommendedText, value: 'recommended' },
      { label: mostValuedText, value: 'best_rated' },
      { label: cheapestServiceText, value: 'cheapest' },
      { label: mostExpensiveServiceText, value: 'more_expensive' },
    ])

    const categoryNodes = ref<CheckBoxNode[]>([])

    const {
      selectedPromos,
      multiPromoOptions,
      onPromoCategoryNodeClick,
    } = useMultiPromoFilter(categoryNodes)

    const sessionLocation = useSessionLocation()

    // --- Get data ---

    const countries = await useFetchProxy<Array<Location>>(
      '/api/locations/locations-by-type',
      { method: 'post', body: { type: 'country' } },
    )

    const spaLandingByLocation = await useFetchProxy<SpaLandingByLocation>(
      '/api/spas/spa-list-by-uri',
      {
        method: 'post',
        body: { uri }
      })

    const location = spaLandingByLocation.location

    const [spas, filterLocationsTree, availablePromotions] = await Promise.all([
      (async () => {
        const uuids = spaLandingByLocation.payload
        return await useFetchProxy<Array<SpaMiniList>>(
          '/api/spas/spa-mini-list-by-uuid-list',
          {
            method: 'post',
            body: { uuids }
          }
        )
      })(),
      (async () => {
        let trees: Array<LocationTree> = []
        if (location) {
          trees = await useFetchProxy<LocationTree[]>(
            '/api/spas/location-tree-siblings-by-parent-uuid',
            {
              method: 'post',
              body: { uuid: location.parentUUID }
            }
          )
        } else {
            trees = await useFetchProxy<LocationTree[]>(
            '/api/spas/location-tree-with-no-parent',
            {
              method: 'post',
              body: { lang: runtimeConfig.public.language },
            }
          )
        }

        return trees.map((tree) => ({
          ...tree,
          expanded: true
        }))
      })(),
      (async () => {
        return await useFetchProxy<Array<Promotion>>(
          '/api/promotions/promotion-list-public', {
            key: 'spa-list-promotions'
          })
      })(),
    ])

    let generalParentLocation: Location | null = null
    if (location && location.parentUUID) {
      generalParentLocation = await useFetchProxy<Location>(
        '/api/locations/location-by-uuid',
        {
          method: 'post',
          body: { uuid: location.parentUUID }
        }
      )
    }

    // --- End Get data ---

    categoryNodes.value = availablePromotions
      .map((promotionTag) => {
        const texts: CheckBoxNode['texts'] = {}
        Object.entries(promotionTag.texts).forEach(([key, value]) => texts[key] = value.title)

        const checkboxNode = CheckBoxNode.copy({
          id: promotionTag.uuid,
          label: promotionTag.title,
          value: false,
          texts,
          metadata: { promotionTag }
        })

        const spaCollectionFiltered = spas.filter((spa) => {
          return spa.promoTags
            .map((tag) => tag.tag.uuid)
            .includes(promotionTag.uuid)
        })

        if (spaCollectionFiltered.length) {
          return checkboxNode
        }

        return null
      })
      .filter((node) => !!node) as CheckBoxNode[]

    const spasView = computed(() => {
      if (!spas.length) {
        return spas
      }

      let view: Array<SpaMiniList> = spas.slice()

      const selectedCountry = sessionLocation.storedLocationCountry.value
      if (!location && selectedCountry) {
        view = view.filter((spa) => {
          return spa.location.country === selectedCountry.countryCode
        })
      }

      // FIXME: Mover esto a un SpaCollection
      switch (sortOrder.value.value) {
        case'recommended':
          view = [
            ...view.filter((spa) => spa.statusQUO === 'open'),
            ...view.filter((spa) => spa.statusQUO === 'premium'),
            ...view.filter((spa) => spa.statusQUO === 'grow'),
            ...view.filter((spa) => spa.statusQUO === 'start'),
            ...view.filter((spa) => spa.statusQUO === 'none'),
          ]
          break

        case'best_rated':
          view = view.sort((a, b) => b.score - a.score)
          break

        case'cheapest':
          view = view
            .filter((spa) => !!spa.services.length)
            .sort((a, b) => {
              const aServ = (new ServiceCollection(a.services)).getTheCheapest()
              const bServ = (new ServiceCollection(b.services)).getTheCheapest()

              if (!aServ || !bServ) {
                return -1
              }

              return aServ.price.amount < bServ.price.amount ? -1 : 1
            })
          break

        case'more_expensive':
          view = view
            .filter((spa) => !!spa.services.length)
            .sort((a, b) => {
              const aServ = (new ServiceCollection(a.services)).getTheMostExpensive()
              const bServ = (new ServiceCollection(b.services)).getTheMostExpensive()

              if (!aServ || !bServ) {
                return -1
              }

              return aServ.price.amount < bServ.price.amount ? 1 : -1
            })
          break
      }

      // Ponemos los spas online arriba del todo.
      const onlineSpas = view.filter((spa) => spa.status === 'online')
      const notOnlineSpas = view.filter((spa) => spa.status !== 'online')
      view = onlineSpas.concat(notOnlineSpas)

      if (!selectedPromos.value.length) {
        return view
      }

      view = view.filter((spa) => {
        let include = false
        for (const categoryNode of selectedPromos.value) {
          const hasTag = spa.promoTags
            .map((tag) => tag.tag.uuid)
            .includes(categoryNode.id)
          if (hasTag) {
            include = true
            break
          }
        }

        return include
      })

      return view
    })

    const foundTexts = capitalizeFirstWord(translateByKey('found', false))
    const foundTextp = capitalizeFirstWord(translateByKey('found', true))

    const subtitle = computed(() => {
      if (spasView.value.length === 1) {
        return `${foundTexts} 1 Spa`
      }

      return `${foundTextp} ${spasView.value.length} Spas`
    })

    const url = joinPaths(domain, uri)
    useHead(getSpaListPageMetadata(url, location, spas.length ? spas[0] : undefined))

    const locationFilterBaseUrl = slashUri(runtimeConfig.public.urls.spasBaseUrl)

    return {
      isModalDisplayed,
      setIsModalDisplayed,

      // Filtro de localizaciones
      countries,
      generalParentLocation,
      filterLocationsTree,
      locationFilterBaseUrl,
      storedLocationCountry: sessionLocation.storedLocationCountry,

      subtitle,

      location,
      spas,
      spasView,
      uri,

      // Selector de orden
      sortOrder,
      pushSortOrder,
      sortOptions,
      selectedSortOptionLabel,
      // Filtro de categorías
      selectedPromos,
      multiPromoOptions,
      onPromoCategoryNodeClick,
    }
  }
})
</script>

<style lang="scss" scoped>
.spa-list {
  &__header {
    @apply px-2;
    @apply pb-2;

    @screen lg {
      @apply px-0;
    }
  }

  &__title {
    @apply text-xl;
    @apply text-spl-dark;
    @apply mt-20p mb-20p;
    @apply font-semibold;

    @apply px-2;

    @screen lg {
      @apply px-0;
      @apply text-3xl;
    }

  }

  &__list-wrapper {
    @apply py-4;

    @screen lg {
      @apply gap-3;
      @apply grid;
      grid-template-columns: 25% 75%;
    }
  }

  &__filters {
    @apply py-4;
    @apply px-4;

    &__title {
      @apply text-lg;
      @apply font-bold;
      @apply text-spl-dark;

      @apply w-full;
      @apply py-1;
      @apply my-1;
      @apply border-b-1;
      @apply border-spl-secondary;
    }

    &__expanded-filter {
      @apply py-2;

      &__header {
        @apply font-bold;
        @apply py-2;

        @apply text-spl-dark;
      }

      &__checkbox-node {
        input, label {
          @apply cursor-pointer;
        }

        label {
          @apply px-2;
          @apply text-spl-dark;
        }
      }

      &__checkbox-node:hover {
        label {
          @apply font-bold;
        }
      }

      &__checkbox-node.selected {
        label {
          @apply font-bold;
        }
      }
    }

    &__filter-selector {
      @apply my-2;
    }
  }

  &__mobile-selected-filters {
    @apply px-4 py-1;

    &__category {
      @apply m-1;
    }
  }

  &__mobile-filters {
    &__header {
      &__title {
        @apply text-xl;
        @apply text-spl-dark;
        @apply py-4;
        @apply px-2;
        @apply font-semibold;
      }

      &__controls {
        @apply py-2;
        @apply flex justify-end;
      }
    }

    &__button {
      @apply flex items-center;
      @apply text-sm;

      @apply px-4;
      @apply py-2;
      @apply border-r-1;

      &__icon {
        @apply mr-1;
      }
    }

    &__expanded-filter {
      &__header {
        @apply font-bold;
        @apply py-2;

        @apply text-spl-dark;
      }

      @apply pb-2 mb-2;
    }

    &__sort-selector {
      @apply text-sm;
      @apply px-4;
      @apply py-2;

      &__label {
        @apply font-bold;
        @apply italic;
      }
    }
  }

  &__spas {
    &__header {
      @apply hidden;

      @screen lg {
        @apply flex;
        @apply justify-between;
        @apply items-center;
      }

      @apply px-2 pb-1;

      &__subtitle {
        @apply text-spl-dark;
        @apply text-sm;
      }

      &__sort-selector {
        @apply my-2;

        &__label {
          @apply font-bold;
          @apply italic;
        }
      }
    }
  }

  &__no-spas {
    @apply text-center;

    @apply py-6;
    @apply my-50p;

    @apply border;
    @apply border-spl-gray-1;
    border-radius: 16px;

    &__header {
      @apply font-bold;
      @apply text-spl-acent-dark;
    }

    &__location {
      @apply text-spl-gray-4;
    }

    &__button {
      @apply my-10p;
    }
  }
}
</style>
