import { GetAwayPurchase } from '../ts/entity/GetAwayPurchase'
import type { VoucherPurchase } from '../ts/entity/VoucherPurchase'
import EventBus from './event-bus'

export default class PlausibleAnaliticsEventBus {
  static on(func: any): number {
    return EventBus.on('plausible-event', func)
  }

  static off(id: number) {
    return EventBus.off('plausible-event', id)
  }

  static getVoucherCode(purchase: VoucherPurchase): string {
    if (purchase.locator.voucherCode) {
      return purchase.locator.voucherCode
    }

    return ''
  }

  static send(name: string, meta: object | undefined) {
    EventBus.emit('plausible-event', name, meta)
  }

  static sendAddToCartVoucher(purchase: VoucherPurchase) {
    const numericPrice = purchase.pricing.final.amount / 100
    const price = Number(numericPrice.toFixed(2))

    const transaction = purchase.locator.purchaseLocator
    const voucherCode = this.getVoucherCode(purchase)

    this.send('Add To Cart', {
      revenue: {amount: price, currency: 'EUR'},
      props: {
        purchase_id: transaction,
        voucher_id: voucherCode,
      }
    })
  }

  static sendAddToCartGetAway(purchase: GetAwayPurchase) {
    const rate = GetAwayPurchase.getSelectedRate(purchase)
    if (!rate) {
      return
    }

    const numericPrice = rate.basePriceWithDiscount.amount / 100
    const price = Number(numericPrice.toFixed(2))

    const transaction = purchase.locators.purchase

    this.send('Add To Cart', {
      revenue: { amount: price, currency: 'EUR' },
      props: {
        purchase_id: transaction,
        voucher_id: '',
      }
    })
  }

  static sendAddShippingInfoVoucher(purchase: VoucherPurchase) {
    const numericPrice = purchase.pricing.final.amount / 100
    const price = Number(numericPrice.toFixed(2))

    const transaction = purchase.locator.purchaseLocator
    const voucherCode = this.getVoucherCode(purchase)

    this.send('Add Shipping Info', {
      revenue: {amount: price, currency: 'EUR'},
      props: {
        purchase_id: transaction,
        voucher_id: voucherCode,
      }
    })
  }

  static sendAddShippingInfoGetAway(purchase: GetAwayPurchase) {
    const rate = GetAwayPurchase.getSelectedRate(purchase)
    if (!rate) {
      return
    }

    const numericPrice = rate.basePriceWithDiscount.amount / 100
    const price = Number(numericPrice.toFixed(2))

    const transaction = purchase.locators.purchase

    this.send('Add Shipping Info', {
      revenue: {amount: price, currency: 'EUR'},
      props: {
        purchase_id: transaction,
        voucher_id: '',
      }
    })
  }

  static sendAddPaymentInfoVoucher(purchase: VoucherPurchase) {
    const numericPrice = purchase.pricing.final.amount / 100
    const price = Number(numericPrice.toFixed(2))

    const transaction = purchase.locator.purchaseLocator
    const voucherCode = this.getVoucherCode(purchase)

    this.send('Add Payment Info', {
      revenue: {
        currency: 'EUR',
        amount: price
      },
      props: {
        purchase_id: transaction,
        voucher_id: voucherCode,
      }
    })
  }

  static sendAddPaymentInfoGetAway(purchase: GetAwayPurchase) {
    const rate = GetAwayPurchase.getSelectedRate(purchase)
    if (!rate) {
      return
    }

    const numericPrice = rate.basePriceWithDiscount.amount / 100
    const price = Number(numericPrice.toFixed(2))

    const transaction = purchase.locators.purchase

    this.send('Add Payment Info', {
      revenue: {
        currency: 'EUR',
        amount: price
      },
      props: {
        purchase_id: transaction,
        voucher_id: '',
      }
    })
  }

  static sendPurchaseVoucher(purchase: VoucherPurchase) {
    const numericPrice = purchase.pricing.final.amount / 100
    const price = Number(numericPrice.toFixed(2))

    const transaction = purchase.locator.purchaseLocator
    const voucherCode = this.getVoucherCode(purchase)

    this.send('Purchase', {
      revenue: {
        currency: 'EUR',
        amount: price
      },
      props: {
        purchase_id: transaction,
        voucher_id: voucherCode,
      }
    })
  }

  static sendPurchaseGetAway(purchase: GetAwayPurchase) {
    const rate = GetAwayPurchase.getSelectedRate(purchase)
    if (!rate) {
      return
    }

    const numericPrice = rate.basePriceWithDiscount.amount / 100
    const price = Number(numericPrice.toFixed(2))

    const transaction = purchase.locators.purchase

    this.send('Purchase', {
      revenue: {
        currency: 'EUR',
        amount: price
      },
      props: {
        purchase_id: transaction,
        voucher_id: '',
      }
    })
  }

  static sendPurchaseVoucherFailure(purchase: VoucherPurchase) {
    const numericPrice = purchase.pricing.final.amount / 100
    const price = Number(numericPrice.toFixed(2))

    const transaction = purchase.locator.purchaseLocator
    const voucherCode = this.getVoucherCode(purchase)

    this.send('Purchase Failure', {
      revenue: {
        currency: 'EUR',
        amount: price
      },
      props: {
        purchase_id: transaction,
        voucher_id: voucherCode,
      }
    })
  }

  static sendPurchaseGetAwayFailure(purchase: GetAwayPurchase) {
    const rate = GetAwayPurchase.getSelectedRate(purchase)
    if (!rate) {
      return
    }

    const numericPrice = rate.basePriceWithDiscount.amount / 100
    const price = Number(numericPrice.toFixed(2))

    const transaction = purchase.locators.purchase

    this.send('Purchase Failure', {
      revenue: {
        currency: 'EUR',
        amount: price
      },
      props: {
        purchase_id: transaction,
        voucher_id: '',
      }
    })
  }
}
