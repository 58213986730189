<template>
  <transition name="fade">
    <div
      v-if="isDisplayed"
      class="notification__wrapper"
    >
      <Card class="notification">
        <p class="notification__title">
          <DynLang
            :by-key="title"
            :default-label="title"
          />
        </p>
        <div class="notification__content">
          <div class="notification__content__text">
            <DynLang
              :by-key="content"
              :default-label="content"
            />
          </div>
          <div class="notification__content__buttons">
            <SplButton
              v-if="isAcceptButtonDisplayed"
              variant="acent"
              size="sm"
              @click="emitAcceptEvent()"
            >
              Aceptar
            </SplButton>
            <SplButton
              v-if="isCancelButtonDisplayed"
              variant="link"
              @click="emitCancelEvent()"
            >
              Cancelar
            </SplButton>
          </div>
        </div>
      </Card>
    </div>
  </transition>
</template>

<script lang="ts">
import Card from './Card.vue'
import SplButton from './form/SplButton.vue'

export default defineNuxtComponent({
  components: {
    Card,
    SplButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    isDisplayed: {
      type: Boolean,
      default: () => true,
    },
    isAcceptButtonDisplayed: {
      type: Boolean,
      default: () => false,
    },
    isCancelButtonDisplayed: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(_, { emit }) {
    function emitAcceptEvent() { emit('onAccept') }
    function emitCancelEvent() { emit('onCancel') }

    return {
      emitAcceptEvent,
      emitCancelEvent,
    }
  },
})
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  @apply opacity-100;
  @apply transition-all duration-300 ease-in-out;
}
.fade-enter,
.fade-leave-to {
  @apply opacity-0;
  @apply transform -translate-y-200p;
}

.notification__wrapper {
  @apply w-full;
  @apply fixed left-0;
  @apply p-2;

  z-index: 20;

  @screen lg {
    @apply top-30p left-0;
  }
}

.notification {
  @apply w-full;

  @apply border-2 border-spl-acent;
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.2);

  @apply bg-spl-white;

  @screen lg {
    @apply w-1085p;
    @apply mx-auto;
  }

  padding: theme('spacing.6') !important;
  &__title {
    @apply text-spl-acent-dark;
    @apply font-bold;
  }
  &__content {

    &__buttons {
      @apply flex;
      @apply justify-end;
    }
  }
}
</style>
