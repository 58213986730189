<template>
  <div
    class="service-detail"
    itemscope
    itemtype="https://schema.org/Product"
  >
    <!-- Extra snippets -->
    <img
      class="util-snippet"
      itemprop="image"
      alt="Product Image"
      :src="firstImageUrl"
    >
    <div
      class="util-snippet"
      itemprop="sku"
    >
      {{ service.uuid }}
    </div>
    <div
      itemprop="brand"
      itemtype="https://schema.org/Brand"
      itemscope
    >
      <meta
        itemprop="name"
        :content="spa.name"
      >
    </div>

    <Breadcrumb :name-override-stack="[spa.name, serviceName]" />

    <Card
      mobile-with-border
      class="service-detail__card"
    >
      <div class="service-detail__header">
        <div class="service-detail__header__score">
          <LeafScoreMeterAtom :score="score" />
        </div>
        <div
          v-if="score"
          property="aggregateRating"
          itemprop="aggregateRating"
          itemscope
          itemtype="https://schema.org/AggregateRating"
        >
          <span
            class="util-snippet"
            itemprop="ratingValue"
          >
            {{ score }}
          </span>
          <span
            class="util-snippet"
            itemprop="bestRating"
          >
            {{ maxScore }}
          </span>
          <span
            class="util-snippet"
            itemprop="reviewCount"
          >
            {{ reviewCount }}
          </span>
        </div>
        <div>
          <h1
            class="service-detail__header__service-name"
            itemprop="name"
          >
            {{ serviceName }}
          </h1>
          <div class="service-detail__header__spa-name">
            <Lang
              by-key="XX-in-XX"
              :params="{left: '', right:spa.name }"
            />
          </div>
        </div>
      </div>

      <!-- Spa and hotel names -->
      <HotelNameStarsAtom
        v-if="spa.hotel"
        class="service-detail__location"
        text-small
        inline-stars
        :star-size="12"
        :hotel="spa.hotel"
      />

      <LocationLink
        class="service-detail__address"
        :location="spa.location"
        show-icon
        display-full-address
      />

      <GalleryMolecule
        :images="service.images"
        orientation="landscape"
        class="spa-detail__gallery"
      >
        <Badge
          v-if="isDisplayedClosedByTemp"
          color="acent"
          class="service-detail-page__header__badge"
        >
          <Lang
            by-key="enjoy-once-spa-is-open"
            capitalize-first
          />
        </Badge>
      </GalleryMolecule>
    </Card>

    <main class="service-detail__content">
      <div>
        <SectionAtom
          class="service-detail__content__description"
          property="description"
          itemprop="description"
          :start-collapsed="true"
          :is-collapsable="true"
          :separator="false"
          :html="description"
        >
          <template #title>
            <p class="service-detail__content__description__title">
              <Lang
                by-key="description"
                capitalize-first
              />
            </p>
          </template>
        </SectionAtom>
      </div>

      <div>
        <Card
          desktop-with-border
          class="service-detail__content__availability"
        >
          <SelectBox
            id="pax-selector"
            :data="paxOptions"
            display-prop="label"
            variant="border"
            class="spa-list__filters__filter-selector"
            @input="pushPaxSelection"
          >
            <template #label>
              <span class="spa-list__mobile-filters__sort-selector__label">
                {{ selectedPaxOptionLabel }}
              </span>
            </template>
          </SelectBox>

          <div
            v-if="service.durationMin && hasCorrectCapacity"
            class="service-detail__content__availability__duration"
          >
            <h3 class="service-detail__content__availability__duration__title">
              <Lang
                by-key="service-duration"
                capitalize-first
              />
            </h3>
            <div class="service-detail__content__availability__duration__numeric">
              {{ service.durationMin }} <Lang
                by-key="minute"
                plural
              />
            </div>
          </div>

          <ServicePurchaseLink
            target-url="/service-booking/checkout"
            type="booking"
            is-external
            :is-available="isBookingAvailable"
            :query="serviceBookingQueryParams"
            :base-price="basePrice"
            :best-price="discountedPrice"
            class="service-booking__link"
          />

          <ServicePurchaseLink
            target-url="/voucher/start/"
            type="buy"
            is-external
            :is-available="isVoucherAvailable"
            :query="servicePurchaseQueryParams"
            :base-price="basePrice"
            :best-price="discountedPrice"
            class="service-booking__link"
          />

          <ServiceBookingSummary
            :is-available="isBookingOrPurchaseAvailable"
            :is-spa-closed-by-temp="spa.status === 'closedtemp'"
            :spa="spa"
            :service="service"
            class="service-detail__summary"
          />
        </Card>
      </div>
    </main>

    <div>
      <SectionAtom
        v-if="generalCondition"
        class="service-detail__content__general-conditions"
        property="description"
        itemprop="description"
        :start-collapsed="true"
        :is-collapsable="true"
        :separator="false"
        :html="generalCondition"
      >
        <template #title>
          <p class="service-detail__content__general-conditions__title">
            <Lang
              by-key="special-conditions"
              capitalize-all
            />
          </p>
        </template>
      </SectionAtom>
    </div>

    <BenefitsCard
      :tags="['BEST_PRICE', 'GIVINGS', 'SAFE_PAYMENT']"
      :can-close="false"
      class="service-detail-page__content__benefits"
    />

    <!-- El Spa -->
    <div>
      <SectionAtom
        class="service-detail__content__description"
        :start-collapsed="true"
        :is-collapsable="true"
        :separator="false"
        :html="spaLongDescription"
      >
        <template #title>
          <p class="service-detail__content__description__title">
            <Lang
              by-key="the-spa"
              capitalize-all
            />
          </p>
        </template>
      </SectionAtom>
    </div>

    <SpaMapAndSchedule :spa="spa" />

    <Separator />

    <SpaReviewMaster :spa-uuid="service.spaUUID" />
  </div>
</template>

<script lang="ts">
import Breadcrumb from '@/core/components/shared/Breadcrumb.vue'
import Card from '@/core/components/shared/Card.vue'
import Separator from '~/core/components/shared/Separator.vue'
import LeafScoreMeterAtom from '@/core/components/spa/atom/LeafScoreMeter.atom.vue'
import HotelNameStarsAtom from '@/core/components/hotel/atom/HotelNameStars.atom.vue'
import LocationLink from '@/core/components/shared/LocationLink.vue'
import Badge from '@/core/components/shared/Badge.vue'
import GalleryMolecule from '@/core/components/shared/gallery/Gallery.molecule.vue'
import SectionAtom from '@/core/components/shared/Section.atom.vue'
import BenefitsCard from '@/core/components/spa/BenefitsCard.vue'
import SpaReviewMaster from '@/core/components/spa-review/molecules/SpaReviewMaster.vue'
import SelectBox from '~/core/components/shared/form/SelectBox.vue'
import ServiceBookingSummary from '~/core/components/spa-service/ServiceBookingSummary.vue'
import ServicePurchaseLink from '~/core/components/spa-service/ServicePurchaseLink.vue'
import SpaMapAndSchedule from '~/core/components/spa/SpaMapAndSchedule.vue'

import { Service } from '@/core/ts/entity/Service'
import { Spa } from '@/core/ts/entity/Spa'

import { translate, translateByKey } from '~/core/ts/util/translate'
import { type Option } from '@/core/composable/shared/useSelectorToQueryParam'
import { usePaxSelector } from '@/core/composable/spa-service/usePaxSelector'
import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import { useLangSelector } from '~/core/composable/shared/useLangSelector'
import { getServiceMetadata } from '~/core/composable/spa-service/useServicePageHeader'

import { joinPaths } from '~/core/ts/util/string'

import ServiceNavigationStack from '~/core/ts/repository/ServiceNavigationStackRepository'
import GA4AnaliticsEventBus from '~/core/events/ga4-analitics'
import ConnectifEventBus from '~/core/events/connectif'

export default defineNuxtComponent({
  components: {
    Breadcrumb,
    Card,
    Separator,
    LeafScoreMeterAtom,
    HotelNameStarsAtom,
    LocationLink,
    Badge,
    GalleryMolecule,
    SectionAtom,
    BenefitsCard,
    SpaReviewMaster,
    SelectBox,
    ServiceBookingSummary,
    ServicePurchaseLink,
    SpaMapAndSchedule,
  },
  async setup() {
    const route = useRoute()
    const runtimeConfig = useRuntimeConfig()
    const domain = runtimeConfig.public.domain

    const paxSelectorOptions = ref<Option[]>([])

    const {
      selectedPax,
      selectedPaxValue,
      pushPaxSelection,
      paxOptions,
      selectedPaxOptionLabel
    } = usePaxSelector(paxSelectorOptions, '0')

    const { currentLang } = useLangSelector()

    onMounted(() => {
      let selected = Number(selectedPax.value.value || '0')
      let shouldUpdate = false

      // Si el valor del parámetro es inválido o no tenemos pax seleccionado,
      //  ponemos el mínimo en el selector de personas.
      if (!selected || selected < service.capacity.min) {
        selected = service.capacity.min
        shouldUpdate = true
      }

      // NOTE: Esto solo funciona cuando el servicio es de pareja.
      const shouldNormalizePax = (selected % service.capacity.min) !== 0
      if (shouldNormalizePax) {
        selected = Service.normalizePaxNumber(service, selected)
        shouldUpdate = true
      }

      if (shouldUpdate) {
        setTimeout(() => pushPaxSelection({ label: 'pax', value: String(selected) }), 500)
      }

      // Se usa para pillar los datos del servicio durante el proceso de compra.
      setTimeout(() => ServiceNavigationStack.push(service), 250)

      if (service && spa) {
        const meta = GA4AnaliticsEventBus.mapService(service, spa)
        GA4AnaliticsEventBus.send('view_item', meta)

        ConnectifEventBus.sendProductVisitedService(service, spa, new Date())
      }
    })

    const uri = route.path
    const service = await useFetchProxy<Service>('/api/services/service-by-uri',  { method: 'post', body: { uri } })
    const spa = await useFetchProxy<Spa>('/api/spas/spa-by-uuid', { method: 'post', body: { uuid: service.spaUUID }})

    Service.addExtraImages(service, spa.images)

    // Valores para los Richsnippets de Google
    // NOTE: Recomendación de David Rodríguez. Si no ha nota que mostrar, ponemos 4 por defecto.
    const score = spa.score ? spa.score : 4
    const maxScore = 5
    const reviewCount = 1

    const firstImageUrl = service.images.length ? service.images[0].url : ''
    const isDisplayedClosedByTemp = service.status == 'disable_by_closed_temp_spa'

    const hasCorrectCapacity = (() => {
      if (service.capacity.min === 0) {
        return false
      }

      if (service.capacity.max === 0) {
        return false
      }

      return true
    })()

    const url = joinPaths(domain, uri)
    useHead(getServiceMetadata(spa, service, url))

    const personLabel = translateByKey('person', false)
    const peopleLabel = translateByKey('person', true)

    const maxPosibleVoucherCount = service.capacity.max === service.capacity.min
      ? (service.capacity.min % 2 === 0 ? 6 : 12)
      : Service.getVoucherCount(service, service.capacity.max)

    paxSelectorOptions.value = Array
      .from(
        { length: maxPosibleVoucherCount },
        (_, index) => service.capacity.min + (index * service.capacity.inc)
      )
      .map((count) => ({
        value: count + '',
        label: count === 1 ? `1 ${personLabel}` : `${count} ${peopleLabel}`
      }))

    const isBookingOrPurchaseAvailable = service.bookingAvailable === 'yes' || service.voucherAvailable
    const count = computed(() => Number(selectedPax.value.value || '1') / service.capacity.inc)

    const serviceBookingQueryParams = computed(() => ({
      spaUUID: spa.uuid,
      serviceUUID: service.uuid,
      serviceRevisionUUID: service.uuidRevision,
      langCode: currentLang,
      brandID: spa.brandId,
      isPackage: service.isPackage,
      serviceUri: service.uri,
      count: count.value,
    }))

    const servicePurchaseQueryParams = computed(() => ({
      spaUUID: spa.uuid,
      serviceUUID: service.uuid,
      serviceRevisionUUID: service.uuidRevision,
      langCode: currentLang,
      count: count.value,
    }))

    const hasACorrectPaxConfig = computed(() => {
      let selected = Number(selectedPaxValue.value || '0')
      let normalized = Service.normalizePaxNumber(service, selected)

      return selected === normalized
    })

    const hasDiscount = Service.hasAValidOffer(service, new Date())
    const basePrice = computed(() => Service.getBasePrice(service, Number(selectedPax.value.value)))
    const discountedPrice = computed(() => Service.getDiscountedPrice(service, new Date(), Number(selectedPax.value.value)))

    // :is-available="service.bookingAvailable === 'yes'"
    const isBookingAvailable = computed(() => {
      if (service.bookingAvailable === 'no') {
        return false
      }

      if (!hasACorrectPaxConfig.value) {
        return false
      }

      return service.isAvailableOn !== 0
    })

    // :is-available="service.voucherAvailable"
    const isVoucherAvailable = computed(() => {
      if (!service.voucherAvailable) {
        return false
      }

      return hasACorrectPaxConfig.value
    })

    // Textos
    const serviceName = translate(service.texts).name
    const description = translate(service.texts).description
    const generalCondition = translate(service.texts).generalCondition
    const spaLongDescription = translate(spa.texts).longDescription

    return {
      service,
      spa,
      firstImageUrl,
      score,
      maxScore,
      reviewCount,

      // Textos
      serviceName,
      description,
      generalCondition,
      spaLongDescription,

      // Estado
      isDisplayedClosedByTemp,
      hasCorrectCapacity,
      isBookingOrPurchaseAvailable,
      serviceBookingQueryParams,
      servicePurchaseQueryParams,
      isBookingAvailable,
      isVoucherAvailable,

      // Pax selector
      pushPaxSelection,
      paxOptions,
      selectedPax,
      selectedPaxOptionLabel,

      // Precios
      hasDiscount,
      basePrice,
      discountedPrice,
    }
  }
})
</script>

<style lang="scss" scoped>
.service-detail {
  &__card {
    @apply my-4;
    @apply mx-1;
    @apply px-2;
    @apply py-2;
  }

  &__header {
    @apply mt-1 mb-1;

    @screen md {
      @apply px-0;
      @apply flex flex-row-reverse;
      @apply justify-between items-center;
    }

    &__score {
      @apply flex flex-row-reverse;

      @apply mt-1;
      @apply mb-2;
      @apply px-2;
    }

    &__service-name {
      @apply text-gray-800;
      @apply font-bold;
      font-size: 21px;
    }

    &__spa-name {
      @apply text-gray-800;
      font-size: 16px;
      @apply my-1;
    }
  }

  &__location {
    @apply mb-2;
    @apply text-gray-900;

    @screen lg {
      @apply mt-1;
      @apply mb-3;
    }
  }

  &__address {
    @apply mt-2 mb-4;
  }

  &__content {
    @apply flex flex-col-reverse;

    @screen lg {
      @apply grid grid-cols-2 gap-4;
    }

    @apply mt-4;
    @apply mb-8;
    @apply py-4;

    &__description {
      @apply p-4;
    }

    &__general-conditions {
      @apply py-4;
    }

    &__availability {
      @apply p-4;
      @apply flex flex-col;
      @apply gap-2;

      @screen lg {
        @apply p-8;
        @apply mx-11;
      }

      &__duration {
        @apply pt-4;
        @apply pb-2;

        @apply font-bold;

        &__title {
          @apply text-lg;
          @apply text-black;
        }
        &__numeric {
          @apply px-4 py-3;
          @apply border-b-2 border-spl-acent-dark;
        }
      }
    }
  }

  &__summary {
    @apply border-t-2;
    @apply mt-20p pt-20p;
  }
}
</style>
